import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosGlobal";
import { useAuth } from "../../utils/AuthProvider";
import QuoteItemList from "./QuoteItemList";
import QuoteDetail from "./QuoteDetail";

const QuotesList = () => {
  const { authState } = useAuth();
  const [quotes, setQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [filteredResults, setFilteredResults] = useState(quotes);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [query, setQuery] = useState("");
  const [categoria, setCategoria] = useState("");

  useEffect(() => {
    setLoading(true);

    axiosInstance(`${process.env.REACT_APP_API_URI}api/requests/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authState.token}`,
      },
    })
      .then((response) => {
        setLoading(false);
        const data = response.data.requests;
        setQuotes(data);

        let filtered = data.filter((quote) => {
          console.log(quote);
          if (categoria && quote.categoria !== categoria) {
            return false; // Filtrar por categoría
          }

          let cotizacion;
          try {
            const cleanedCotizacion = quote?.cotizacion?.cotizacion
              .trim()
              .replace(/^"|"$/g, ""); // Limpiar comillas adicionales
            cotizacion = JSON.parse(cleanedCotizacion);
          } catch (error) {
            console.error("Error parsing cotizacion:", error);
            return false; // Omitir esta cotización si el JSON es inválido
          }
          return (
            quote.numero_cotizacion === Number(query) ||
            cotizacion?.nif?.toLowerCase().includes(query.toLowerCase()) ||
            cotizacion?.nombre?.toLowerCase().includes(query.toLowerCase())
          );
        });

        if (sortField) {
          filtered = filtered.sort((a, b) => {
            const auxA = datosCotizacion(a);
            const auxB = datosCotizacion(b);
            let fieldA, fieldB;
            if (sortField === "name") {
              fieldA = auxA?.nombre?.toLowerCase();
              fieldB = auxB?.nombre?.toLowerCase();
            } else if (sortField === "date") {
              fieldA = new Date(a.createdAt);
              fieldB = new Date(b.createdAt);
            }
            if (fieldA < fieldB) return sortOrder === "asc" ? -1 : 1;
            if (fieldA > fieldB) return sortOrder === "asc" ? 1 : -1;
            return 0;
          });
        }

        setFilteredResults(filtered);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [query, sortField, sortOrder, categoria]);

  const handleQuery = (e) => {
    setQuery(e.target.value);
  };

  const handleCategoriaChange = (e) => {
    setCategoria(e.target.value);
  };

  const seeDetails = (quoteId) => {
    setIsModalOpen(true);
    setSelectedQuote(quoteId);
  };

  const hideDetails = () => {
    setIsModalOpen(false);
  };

  const datosCotizacion = (quote) => {
    if (quote?.cotizacion) {
      try {
        const cleanedCotizacion = quote?.cotizacion.cotizacion
          .trim()
          .replace(/^"|"$/g, ""); // Limpiar comillas adicionales
        const parsedCotizacion = JSON.parse(cleanedCotizacion);
        return parsedCotizacion;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <div className="col">
      <div className="row align-items-end justify-content-between pb-4">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <h1 className="mb-0 lh-1">Listado de cotizaciones</h1>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-9 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <label className="mr-2">Ordenar por:</label>
            <button
              onClick={() => {
                setSortField("name");
                toggleSortOrder();
              }}
              type="button"
              className="btn btn-primary m-2"
            >
              Por nombre{" "}
              {sortField === "name" && (
                <i
                  className={`ml-1 bi bi-arrow-${
                    sortOrder === "asc" ? "up" : "down"
                  }`}
                ></i>
              )}
            </button>
            <button
              onClick={() => {
                setSortField("date");
                toggleSortOrder();
              }}
              type="button"
              className="btn btn-primary m-2"
            >
              Por fecha{" "}
              {sortField === "date" && (
                <i
                  className={`ml-1 bi bi-arrow-${
                    sortOrder === "asc" ? "up" : "down"
                  }`}
                ></i>
              )}
            </button>
          </div>
          <div className="col d-flex align-items-center">
            <label className="mr-2">Categoría:</label>
            <select
              className="form-control rounded-3 p-3 border h-50 m-2"
              value={categoria}
              onChange={handleCategoriaChange}
            >
              <option value="">Todas las categorías</option>
              <option value="autos">Autos</option>
              <option value="hogar">Hogar</option>
              <option value="agrario">Agrario</option>
              <option value="vida">Vida</option>
              <option value="salud">Salud</option>
            </select>
          </div>
          <div className="ml-auto col-sm-12 col-md-6 col-lg-3">
            <input
              type="text"
              className="form-control rounded-3 p-3 border h-50 m-2"
              placeholder="Filtrar"
              value={query}
              onChange={handleQuery}
            />
          </div>
        </div>
      </div>

      <div className="row">
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : quotes.length === 0 ? (
          <div className="row justify-content-center">
            <p className="mt-4 text-center">No se encontraron resultados</p>
          </div>
        ) : (
          filteredResults.length !== 0 &&
          filteredResults.map((quote) => {
            return (
              <QuoteItemList
                quote={quote}
                key={quote._id}
                showDetails={() => seeDetails(quote)}
              />
            );
          })
        )}
      </div>

      {isModalOpen && (
        <QuoteDetail quote={selectedQuote} onClose={hideDetails} />
      )}
    </div>
  );
};

export default QuotesList;
