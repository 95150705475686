import React from 'react';


const ItemDocument = ({ doc }) => {
    return (
        <div key={doc._id}>
            <div className="document card flex-row justify-content-between align-items-center p-4 mb-2 rounded-4">
                <div className='d-flex flex-column'>
                    <h3>{doc?.nombre}</h3>
                    <p>{doc?.comentario}</p>
                </div>
                <a 
                    href={`${process.env.REACT_APP_API_URI}docs/${ doc.url }`}
                    className='btn btn-primary'
                    style={{ height: 'fit-content' }}
                    target='_blank'
                    rel="noreferrer"
                >
                    Ver detalles
                </a>
            </div>
        </div>
    );
}

export default ItemDocument;
