import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const QuoteItemList = ({ quote, showDetails }) => {
  const [cotizacionObj, setCotizacionObj] = useState({});

  useEffect(() => {
    if (quote?.cotizacion) {
      try {
        const cleanedCotizacion = quote?.cotizacion.cotizacion
          .trim()
          .replace(/^"|"$/g, ""); // Limpiar comillas adicionales
        const parsedCotizacion = JSON.parse(cleanedCotizacion);
        setCotizacionObj(parsedCotizacion);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [quote]);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", options);
  };

  return (
    <div className="col-sm-12 col-md-6 col-lg-4">
      <div className="card p-3 mb-4">
        <div>
          <h3 className="fs-5">
            {cotizacionObj?.nombre
              ? cotizacionObj?.nombre
              : "Nombre de la cotización"}
          </h3>
          <p className="mb-0">
            <small className="text-muted">
              {cotizacionObj?.nif ? cotizacionObj?.nif : "NIF de la cotización"}
            </small>
          </p>
          <p>
            <small className="text-muted">
              Nº de la cotización:{" "}
              {quote?.numero_cotizacion
                ? quote?.numero_cotizacion
                : "Número de la cotización"}
            </small>
          </p>
          <p>
            <small className="text-muted">
              Fecha de creación:{" "}
              {quote?.createdAt ? formatDate(quote.createdAt) : ""}
            </small>
          </p>
        </div>
        <div>
          <button
            onClick={showDetails}
            className="btn btn-primary"
            target="_blank"
          >
            Ver detalles
          </button>
        </div>
      </div>
    </div>
  );
};

QuoteItemList.propTypes = {
  quote: PropTypes.object.isRequired,
};

export default QuoteItemList;
