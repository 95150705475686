import React from "react";
import Pagination from "./Pagination";

function PaginationCustom({
  filters,
  getList,
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  const prevData = async (page) => {
    setCurrentPage((prevPage) => prevPage - 1); // Usa una función de actualización para asegurar el valor más reciente
    getList({ ...filters, page: page });
  };

  const nextData = async (page) => {
    setCurrentPage((prevPage) => prevPage + 1); // Usa una función de actualización para asegurar el valor más reciente
    getList({ ...filters, page: page });
  };

  return (
    <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
      <div className="md:col-span-12 text-center">
        <nav aria-label="Page navigation example">
          <ul className="d-inline-flex items-center -space-x-px list-unstyled p-0 m-0">
            {currentPage != 0 && (
              <li>
                <span
                  onClick={() =>
                    currentPage > 1 ? prevData(currentPage - 1) : null
                  }
                  className={`d-flex justify-content-center align-items-center text-secondary bg-white rounded-start border border-light transition ${
                    currentPage > 1 ? "cursor-pointer" : "cursor-not-allowed"
                  }`}
                  style={{ width: "40px", height: "40px" }}
                >
                  <i className="bi bi-arrow-left fs-4 text-[20px] rtl-rotate-180 rtl-mt-n1"></i>
                </span>
              </li>
            )}

            {currentPage < totalPages && (
              <li>
                <span
                  onClick={() =>
                    currentPage < totalPages ? nextData(currentPage + 1) : null
                  }
                  className={`d-flex justify-content-center align-items-center text-secondary bg-white rounded-end border border-light transition ${
                    currentPage < totalPages
                      ? "cursor-pointer"
                      : "cursor-not-allowed"
                  }`}
                  style={{ width: "40px", height: "40px" }}
                >
                  <i className="bi bi-arrow-right fs-4 text-[20px] rtl-rotate-180 rtl-mt-n1"></i>
                </span>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default PaginationCustom;
