import React from 'react';
import Container from '../../Layouts/Container';
import InfoCard from '../../components/pure/InfoCard';


const ThirdPartyLinks = () => {
    return (
        <>
            <h1>Enlaces</h1>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-3 mb-4">
                    <InfoCard 
                        link='https://www.asisa.es/seguros-de-salud'
                        image='/assets/images/logo-asisa-vector.png'
                        title='Asisa'
                        text='Web oficial'                         
                        external={true}
                    />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3 mb-4">
                    <InfoCard 
                        link='https://www.asisa.es/seguros-de-salud'
                        image='/assets/images/logo-asisa-vector.png'
                        title='Asisa'
                        text='Web oficial'                         
                        external={true}
                    />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3 mb-4">
                    <InfoCard 
                        link='https://www.asisa.es/seguros-de-salud'
                        image='/assets/images/logo-asisa-vector.png'
                        title='Asisa'
                        text='Web oficial'                         
                        external={true}
                    />
                </div>
            </div>
        </>
    );
}

export default ThirdPartyLinks;
