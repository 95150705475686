import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../Layouts/Container'

const ErrorPage = () => {
    return (
        <Container width='75%'>
            <div className='m-4'>
                <h1>Oops!</h1>
                <p>Algo ha ido mal, vuelve al inicio.</p>
                <Link to={'/'} className='btn btn-primary'>Volver</Link>
            </div>
        </Container>
    );
}

export default ErrorPage;
