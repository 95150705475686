import React, { useState } from "react";
import Container from "../../Layouts/Container";
import { Outlet } from "react-router-dom";
import InfoCard from "../../components/pure/InfoCard";

const QuotesPage = () => {
  const [openModal, setOpenModal] = useState(false);

  const childrenItems = [
    { link: "/solicitud-de-cotizaciones/autos", text: "Autos" },
    { link: "/solicitud-de-cotizaciones/hogar", text: "Hogar" },
    { link: "/solicitud-de-cotizaciones/agrario", text: "Agrario" },
    { link: "/solicitud-de-cotizaciones/vida", text: "Vida" },
    { link: "/solicitud-de-cotizaciones/salud", text: "Salud" },
  ];

  const handleOpen = () => setOpenModal(!openModal);

  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-9 p-4">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
            <InfoCard
              link={"listado"}
              icon={"bi bi-card-list"}
              title={"Ver solicitudes"}
              text={"Accede al listado de cotizaciones"}
              external={false}
            />
          </div>
          <div
            className="col-sm-12 col-md-6 col-lg-3 mb-4"
            onClick={handleOpen}
          >
            <InfoCard
              icon={"bi bi-receipt"}
              title={"Solicitud de cotizaciones"}
              text={"Realiza una nueva solicitud"}
              external={false}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
            <InfoCard
              link={"enlaces"}
              icon={"bi bi-link"}
              title={"Enlaces"}
              text={"Accede a las webs de nuestros partners"}
              external={false}
            />
          </div>
        </div>

        <Outlet />
      </div>
      {openModal && (
        <div className="custom-modal p-5 position-absolute top-0 start-0 w-100 h-100">
          <div className="card d-flex m-auto p-4 border w-75 h-100 overflow-y-scroll">
            <button
              onClick={handleOpen}
              className="btn close-btn position-sticky top-0 end-0 bg-white p-2 rounded-circle border"
              style={{
                zIndex: 1,
                width: "40px",
                height: "40px",
                alignSelf: "flex-end",
              }}
            >
              <i className="bi bi-x-lg"></i>
            </button>

            {/* Lista de hijos de "Solicitud" */}
            <div className="mt-4">
              <h4>Tipo de Solicitud</h4>
              <ul className="list-group">
                {childrenItems.map((item, index) => (
                  <li key={index} className="list-group-item">
                    <a href={item.link} className="text-decoration-none">
                      {item.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuotesPage;
