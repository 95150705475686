import React from 'react';
import { useAuth } from '../../utils/AuthProvider';
import Container from '../../Layouts/Container';
import axiosInstance from '../../utils/axiosGlobal';
import EmailList from './EmailList';

import { useGoogleLogin } from '@react-oauth/google';

const EmailPage = () => {

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {

            const userInfo = await axiosInstance
              .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
              })
              .then(res => {
                dispatch({type: 'SET_USER', payload: { ...authState, googleCredential: {at: tokenResponse.access_token, email: res.data.email, name: res.data.name} }})
              });
        },
        scope: 'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify'

    });

    const { authState, dispatch } = useAuth();

    const handleLogout = () => {
        dispatch({ type: 'LOGOUT_GOOGLE' });
    };

    return (
        <>
            {authState.googleCredential !== null ? (
                <div className='col-sm-12 col-md-12 col-lg-9 p-4'>
                    <EmailList handleLogged={handleLogout} />
                </div>
            ) : (
                <div className="google-login col-sm-12 col-md-12 col-lg-9">
                    <div className="d-flex justify-content-center align-items-center h-100 w-100">
                        <button className='btn btn-large btn-primary d-flex gap-2 px-3 py-2' onClick={() => login()}>
                            Haz login con Google{' '}
                            <i className="bi bi-google"></i>
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default EmailPage;
