import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosGlobal";
import ItemDocument from './ItemDocument';
import { useAuth } from '../../utils/AuthProvider';

const DocumentsList = () => {

    const [query, setQuery] = useState('')
    const [docs, setDocs] = useState([])
    const [filteredResults, setFilteredResults] = useState(docs);
    const [loading, setLoading] = useState(true)

    const { authState } = useAuth();

    useEffect(() => {

        setLoading(true);
    
        axiosInstance(`${process.env.REACT_APP_API_URI}api/documentos/all`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authState.token}` 
            },
        })
            .then(response => {
                setLoading(false)
                setDocs(response.data)
                setFilteredResults(
                    docs?.filter(doc => doc?.nombre.toLowerCase().includes(query.toLowerCase()))
                )
            })

        
    }, [query])

    const handleQuery = (e) => {
        setQuery(e.target.value)
    }

    return (

        <>
            <div className='row align-items-end justify-content-between pb-4'>
                <div className='col-sm-12 col-md-12 col-lg-9'>
                    <h1 className='mb-0 lh-1'>Documentos</h1>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-3'>
                    <input 
                        type="text" 
                        className='form-control rounded-3 p-3 border h-50 mt-4'
                        placeholder='Filtrar'
                        value={query}
                        onChange={handleQuery}
                    />
                </div>
            </div>

            {loading ? 
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    : docs.length === 0 ?
                        <div className='row justify-content-center align-items-center'>
                            <p className='mt-4 text-center'>No se encontraron resultados</p>
                        </div>
                        : query.length === 0
                            ? docs.map((doc, index) => {
                                return(
                                    <ItemDocument doc={ doc } key={ doc?._id } />
                                )
                            })
                            : filteredResults.map((doc) => {
                                return(
                                    <ItemDocument doc={ doc } key={ doc?._id } />
                                )
                            })

            }

        </>  


    );
}

export default DocumentsList;
