import React, { useEffect, useState } from "react";
import { useAuth } from "../../../utils/AuthProvider";
import PropTypes from "prop-types";
import UseEmail from "../../../hooks/useEmail";

const EmailHeader = ({ handleLogged, createEmail, refreshInbox, nextPage, previousPage, searchTerm }) => {

  const [width, setWidth] = useState(window.innerWidth);

  const { dispatch, authState } = useAuth();
  const {
    params,
    query,
    setQuery,
    pageTokens,
  } = UseEmail();

  useEffect(() => {
    const handleResize = () => {
        setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
        window.removeEventListener('resize', handleResize)
    }
}, [])

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT_GOOGLE",
    });

    handleLogged(false);

    localStorage.removeItem("userToken");
  };


  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex gap-2 align-items-center w-100">
        <button className="btn btn-primary" id="write-email" onClick={createEmail}>
          <i className="bi bi-pen me-2"></i>
          <span>Redactar</span>
        </button>

        <button className="btn btn-secondary" onClick={refreshInbox}>
          <i className="bi bi-arrow-clockwise"></i>
        </button>

        <div className="d-flex gap-2">
          <button className="btn btn-secondary" onClick={() => previousPage()}>
            <i className="bi bi-chevron-left"></i>
          </button>
          
          <button className="btn btn-secondary" onClick={() => nextPage()}>
            <i className="bi bi-chevron-right"></i>
          </button>
          <button className="dropdown-toggle btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="bi bi-search"></i>
          </button>
          <ul className="dropdown-menu p-2">
            <div className="dropdown-item px-1">
              <div className="d-flex">
                <input 
                  type="text" 
                  placeholder="Buscar" 
                  name="search" 
                  id="search" 
                  className="border-0" 
                  style = {{ outline: 0 }}
                  value = {query}
                  onChange={(e) => {
                    setQuery(e.target.value)
                  }}
                />
                <button className="btn btn-primary w-25" onClick={() => searchTerm(params.query)}><i className="bi bi-search"></i></button>
              </div>
            </div>  
          </ul>
        </div>
        {
          width <= 1024 &&
          <button className="btn btn-secondary ms-auto" onClick={() => handleLogout()}>
            <i className="bi bi-box-arrow-right"></i>
          </button>
        }
      </div>

      {authState && width > 1024 &&
        <div className="d-flex flex-column gap-1">
          <small className="mb-0">Hola {authState?.googleCredential?.name}</small>
          <small onClick={() => handleLogout()} style={{ cursor: "pointer" }}>
            Cerrar sesión
          </small>
        </div>
      }
    </div>
  );
};

EmailHeader.propTypes = {
  handleLogged: PropTypes.func.isRequired,
  createEmail: PropTypes.func.isRequired,
  refreshInbox: PropTypes.func.isRequired,
};

export default EmailHeader;
