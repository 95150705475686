import React from 'react';
import InfoCard from '../../components/pure/InfoCard';

const Dashboard = () => {
    return (
        <div className='col-sm-12 col-md-12 col-lg-9 p-4' style={{ boxSizing: 'border-box' }}>
            <h1>Escritorio</h1>

            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <InfoCard 
                        link={'buzon'} 
                        icon={'bi bi-inboxes'} 
                        title={ 'Buzón de notificaciones' } 
                        text={ 'Comprueba tu bandeja de entrada desde la app' } 
                        external={false}
                    />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <InfoCard 
                        link={'solicitud-de-cotizaciones'} 
                        icon={'bi bi-receipt'} 
                        title={ 'Solicitud de cotizaciones' } 
                        text={ 'Realiza una nueva solicitud' } 
                        external={false}
                    />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <InfoCard 
                        link={'gestion-documental'} 
                        icon={'bi bi-file-earmark-text'} 
                        title={ 'Gestión de documentos' } 
                        text={ 'Gestiona toda la documentación con un solo clic' } 
                        external={false}
                    />
                </div>

            </div>

            <div className="row">

                <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <InfoCard 
                        link={'gestion-documental/subir-archivo'} 
                        icon={'bi bi-upload'} 
                        title={ 'Subir un nuevo documento' } 
                        text={ 'Sube tus archivos a la app' } 
                        external={false}
                    />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <InfoCard 
                        link={'gestion-documental/documentos'} 
                        icon={'bi bi-card-list'} 
                        title={ 'Documentos' } 
                        text={ 'Consulta el listado y descarga los documentos que necesites' } 
                        external={false}
                    />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <InfoCard 
                        link={'usuarios'} 
                        icon={'bi bi-people'} 
                        title={ 'Usuarios' } 
                        text={ 'Consulta la lista de usuarios registrados' } 
                        external={false}
                    />
                </div>
                
            </div>
        </div>
    );
}

export default Dashboard;
