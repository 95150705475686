import React from 'react';
import PropTypes from 'prop-types';


const Container = ({ width, height, flexBasis, children }) => {
    return (
        <div className='row' style={{ maxWidth: '100%' }}>
            { children }
        </div>
    );
}

Container.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string,
    flexBasis: PropTypes.string,
    children: PropTypes.any.isRequired
}


export default Container;
