const UseHandleEmail = () => {

    const extractEmailFromHeader = (header) => {

        if (header.includes('<') && header.includes('>')) {
            const regex = /<([^>]+)>/; // Expresión regular para extraer el email entre '<' y '>'
            const match = regex.exec(header);
            
            if (match && match[1]) {
                return match[1];
            }
        }
        
        return header; // Si no se encuentra un email, devuelve null
    }
        
    return (
        extractEmailFromHeader
    );
}

export default UseHandleEmail;
