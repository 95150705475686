import React, { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuth } from "../../../utils/AuthProvider";
import axiosInstance from '../../../utils/axiosGlobal';
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FileInputField = React.forwardRef((props, ref) => (
  <input type="file" ref={ref} {...props} />
));

const validationSchema = Yup.object().shape({
  comment: Yup.string(),
});


//subida de documentacion
const DocumentsForm = () => {
  const { authState } = useAuth();
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [comment, setComment] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const fileInputRef = useRef(null);

  //definimos la funcion de arrastrar archivos
  const onDrop = (files) => {
    const newFiles = files.map((file) => ({
      file,
    }));
    setAcceptedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeFile = (file) => {
    const updatedFiles = acceptedFiles.filter((f) => f.file !== file);
    setAcceptedFiles(updatedFiles);
  };

  //funcion de cambiar el comentario que acompaña al documento
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  //funcion que maneja el envio del formulario
  const handleSubmit = () => {
    const formData = new FormData();
    acceptedFiles.forEach((file, index) => {
      formData.append(`files[${index}]`, file.file); 
    });
    formData.append("comment", comment);

    axiosInstance
      .post(`${process.env.REACT_APP_API_URI}api/documentos/create`, formData, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      })
      .then(() => {
        setFormSubmitted(true);
        setAcceptedFiles([]);
        setComment("");
        setError(null);
        toast.success("Archivos cargados con éxito", {
          position: "top-right",
        });
      })
      .catch((error) => {
        console.log(error);
        setError(
          "Hubo un problema al enviar el formulario. Por favor, intenta nuevamente."
        );
        toast.error(
          "Hubo un problema al enviar los archivos, inténtelo de nuevo",
          {
            position: "top-right",
          }
        );
      });
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({ onDrop });

  const initialValues = {
    file: null,
    comment: "",
  };

  const validate = (values) => {
    const errors = {};
    if (acceptedFiles.length === 0) {
      errors.file =
        "Debes seleccionar al menos un archivo antes de enviar el formulario";
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={validate}
    >
      {({ setFieldValue }) => (
        <Form id="docsForm" className="mt-4">
          <div
            {...getRootProps({
              className:
                "dropzone drop-container d-flex flex-column justify-content-center align-items-center gap-4 border p-3 mb-4 rounded bg-secondary-subtle",
            })}
          >
            <FileInputField
              {...getInputProps({ name: "file", ref: fileInputRef })}
              onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
              }}
            />
            {isDragActive ? (
              <p>Suelta aquí tus archivos</p>
            ) : (
              <p>
                Arrastra tus archivos hasta aquí, o haz click para seleccionar
                un archivo
              </p>
            )}
          </div>

          <ErrorMessage name="file" component="div" className="text-danger" />

          {acceptedFiles.map((fileItem, index) => (
            <div key={index} className="file-item">
              <div className="d-flex align-items-center">
                <i className="bi bi-file-earmark"></i>
                <p className="mb-0 ms-2">{fileItem.file.name}</p>
                <button
                  className="btn btn-link"
                  onClick={() => removeFile(fileItem.file)}
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
            </div>
          ))}

          {isDragReject && (
            <div className="alert alert-danger mt-4" role="alert">
              El archivo que intentas subir no está permitido
            </div>
          )}

          <div className="form-floating mt-4">
            <Field
              as="textarea"
              className="form-control"
              placeholder="comentarios"
              id="comentarios"
              cols="30"
              name="comment"
              value={comment}
              onChange={handleCommentChange}
            />
            <label htmlFor="comentarios">Comentarios</label>
            <ErrorMessage
              name="comment"
              component="div"
              className="text-danger"
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary mt-4 ms-auto d-flex justify-content-end gap-2"
          >
            <i className="bi bi-upload"></i>
            Subir archivos
          </button>

          <ToastContainer />
        </Form>
      )}
    </Formik>
  );
};

export default DocumentsForm;
