import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

const InfoCard = ({ link, icon, title, text, image, external  }) => {

    return (
        <Link to={ link } className='text-decoration-none' target={ external ? '_blank' : '_self' }>
            <div className='h-100'>
                <div className="card text-start h-100"> 
                    <div className="card-body">
                        { 
                            image 
                            ? <img className='w-50 mb-3' src={ image } alt={ title } /> 
                            : <i className={ `${ icon } fs-4` }></i>
                        }
                        <h4 className="card-title">{ title }</h4>
                        <p className="card-text">{ text }</p>
                    </div>
                </div>
            </div>
        </Link>
    );
}

InfoCard.propTypes = {
    link: PropTypes.string.isRequired,
    icon: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    external: PropTypes.bool.isRequired
}

export default InfoCard;
