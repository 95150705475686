import React from "react";
import MenuItem from "../pure/MenuItem";
import { useRoles } from "../../utils/AuthProvider";

const Navbar = () => {
  const roles = useRoles();

  return (
    <aside className="navbar col-sm-0 col-md-3 col-lg-3 d-flex flex-column justify-content-between ps-3 py-4 pe-5 border-end sticky-top z-1">
      <nav className="d-flex flex-column mt-5">
        <MenuItem link="/" icon="bi bi-house" text="Escritorio" />

        {!roles.includes("asesoria") && (
          <MenuItem link="/buzon" icon="bi bi-inboxes" text="Buzón" />
        )}

        <MenuItem link="/usuarios" icon="bi bi-people" text="Usuarios" />

        <div className="d-flex justify-content-between position-relative">
          <MenuItem
            // link='/solicitud-de-cotizaciones/listado'
            icon="bi bi-receipt"
            text="Solicitud de Cotizaciones"
            hasChildren={true}
          >
            <MenuItem
              link="/solicitud-de-cotizaciones/listado"
              icon="bi bi-card-list"
              text="Solicitudes"
            />
            <MenuItem
              link="/solicitud-de-cotizaciones/enlaces"
              icon="bi bi-link"
              text="Enlaces"
            />

            <MenuItem
              hasChildren={true}
              icon="bi bi-plus-square"
              text="Nueva solicitud"
            >
              <MenuItem
                link="/solicitud-de-cotizaciones/autos"
                icon="bi bi-card-list"
                text="Autos"
              />
              <MenuItem
                link="/solicitud-de-cotizaciones/hogar"
                icon="bi bi-card-list"
                text="Hogar"
              />
              <MenuItem
                link="/solicitud-de-cotizaciones/agrario"
                icon="bi bi-card-list"
                text="Agrario"
              />
              <MenuItem
                link="/solicitud-de-cotizaciones/vida"
                icon="bi bi-card-list"
                text="Vida"
              />
              <MenuItem
                link="/solicitud-de-cotizaciones/salud"
                icon="bi bi-card-list"
                text="Salud"
              />
            </MenuItem>
            <i className="menu-icon bi bi-chevron-down fs-4 position-absolute"></i>
          </MenuItem>
        </div>

        <div className="d-flex justify-content-between position-relative">
          <MenuItem
            // link='/gestion-documental'
            icon="bi bi-file-earmark-text"
            text="Gestión documental"
            hasChildren={true}
          >
            <MenuItem
              link="/gestion-documental/subir-archivo"
              icon="bi bi-upload"
              text="Subir archivo"
            />

            <MenuItem
              link="/gestion-documental/documentos"
              icon="bi bi-card-list"
              text="Documentos"
            />
            <i className="menu-icon bi bi-chevron-down fs-4 position-absolute"></i>
          </MenuItem>
        </div>
      </nav>
    </aside>
  );
};

export default Navbar;
