import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useAuth } from '../../utils/AuthProvider';
import HamburguerMenu from '../pure/HamburguerMenu';
 
const Header = () => {

    const { dispatch } = useAuth();
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleLogout = () => {
        dispatch({
            type: 'LOGOUT'
        })
    }

    return (
        <header className='d-flex justify-content-between align-items-center w-100 z-3 shadow border-end py-4 px-5'>
            <div>
                <figure className='mb-0'>
                    <Link to='/'>
                        <img 
                            src="/assets/images/logo-main.svg" 
                            alt="Logo main" 
                            className='img-fluid'
                            style={{maxWidth: '300px'}}
                        />
                    </Link>
                </figure>
            </div>
            
            <div>
                <div className="dropdown">

                    {

                        width > 1024 ?
                        <>
                            <button className="dropdown-toggle avatar rounded-circle border bg-secondary-slate bg-opacity-10" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className='bi bi-person fs-4'></i>
                            </button>

                            <ul className="dropdown-menu">
                                <li>
                                    <a 
                                        className="dropdown-item" 
                                        href="#" 
                                        onClick={handleLogout}
                                    >
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </>
                        :
                        <HamburguerMenu />

                    }

                </div>
            </div>
        </header>
    );
}

export default Header;
