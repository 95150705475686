import React from 'react';
import PropTypes from 'prop-types';
import QuoteForm from '../../components/pure/forms/QuoteForm';


const QuoteDetail = ({ quote, onClose }) => {

    function formatKey(key) {
        const formattedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
        return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
    }

    return (
        <div className='custom-modal p-5 position-absolute top-0 start-0 w-100 h-100'>
            <div className='card d-flex m-auto p-4 border w-75 h-100 overflow-y-scroll'>
                <button 
                    onClick={onClose}
                    className='btn close-btn position-sticky top-0 end-0 bg-white p-2 rounded-circle border'
                    style={{ zIndex: 1, width: '40px', height: '40px', alignSelf: 'flex-end' }}
                >
                    <i className="bi bi-x-lg"></i>
                </button>

                <div className='d-flex gap-3'>
                    <h3 className='fs-5'>{ JSON.parse(quote?.cotizacion?.cotizacion)?.nombre }</h3>
                    <em><small className='text-muted'>{ JSON.parse(quote?.cotizacion?.cotizacion)?.nif }</small></em>
                </div>

                <div>
                    <QuoteForm isView = { true } quote = { JSON.parse(quote?.cotizacion?.cotizacion) } />

                    <a className="btn btn-primary" href={`${process.env.REACT_APP_API_URI}solicitudes/${quote.url}`} target='_blank' rel="noreferrer">
                        Ver adjunto
                    </a>
                </div>
            </div>
        </div>
    );
};


QuoteDetail.propTypes = {
    quote: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};


export default QuoteDetail;
