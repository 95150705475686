import React, { useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Formik, Form, Field, resetForm, ErrorMessage } from "formik";
import {
  quoteValidation,
  quoteInitialValues,
} from "./validations/quoteValidation";
import { useAuth } from "../../../utils/AuthProvider";
import axiosInstance from "../../../utils/axiosGlobal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FileInputField = React.forwardRef((props, ref) => (
  <input type="file" ref={ref} {...props} />
));

const baseOptions = {
  vacunoCarne: false,
  vacunoLeche: false,
  equino: false,
  ovinoCaprino: false,
  porcino: false,
  avicola: false,
  otros: false,
};

//formulario de solicitud, dejo este en el resto de solicitudes para que puedas trabajar sobre él como ejemplo
//para usarlo en cada tipo de solicitud de cotización, duplícalo, cámbiale el nombre y llámalo desde su categoría correspondiente
const QuoteForm = ({ isView = false, quote = {} }) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [initialOptions, setInitialOptions] = useState(baseOptions);

  const { authState } = useAuth();

  const fileInputRef = useRef(null);

  const updateSelectedOption = (trigger, option) => {
    // Actualizar el estado para todos los elementos
    const updatedOptions = { ...initialOptions };
    Object.keys(updatedOptions).forEach((key) => {
      updatedOptions[key] = false;
    });

    // Establecer el valor correspondiente a true para el elemento actual
    updatedOptions[option] = true;

    // Actualizar el estado
    setInitialOptions((prevOptions) => ({ ...prevOptions, ...updatedOptions }));
  };

  //manejo para arrastrar los archivos
  const onDrop = (files) => {
    const newFiles = files.map((file) => ({
      file,
    }));
    setAcceptedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeFile = (file) => {
    const updatedFiles = acceptedFiles.filter((f) => f.file !== file);
    setAcceptedFiles(updatedFiles);
  };

  //manejo del envio de formulario
  const handleSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    //adjuntamos los documentos al formulario
    acceptedFiles.length > 0 && formData.append(`files`, acceptedFiles[0].file);

    //generamos una cotizacion dentro de la solicitud como un json, para poder guardar cualquier tipo de campo dentro del atributo 'cotizacion'
    const formDataToJson = JSON.stringify({
      ...Object.entries(values).reduce((acc, [key, value]) => {
        if (value && key !== "adjunto") {
          acc[key] = value;
        }
        return acc;
      }, {}),
      ...Object.entries(initialOptions).reduce((acc, [key, value]) => {
        if (value && key !== "adjunto") {
          acc[key] = value;
        }
        return acc;
      }, {}),
    });

    formData.append("cotizacion", formDataToJson);

    axiosInstance
      .post(`${process.env.REACT_APP_API_URI}api/requests/create`, formData, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Solicitud creada con éxito", {
            position: "top-right",
          });
          resetForm();
        }
      })
      .catch((error) => {
        toast.error("Error al crear la solicitud", {
          position: "top-right",
        });
      });
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({ onDrop });

  return (
    <Formik
      validationSchema={quoteValidation}
      initialValues={quoteInitialValues}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form className="mt-4">
          <div>
            <div className="bg-primary bg-opacity-25 py-3 px-4 rounded-4 me-3 mb-3">
              <h2 className="lh-1 text fs-3 mb-0">Datos del solicitante</h2>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="form-group p-3">
                  <label htmlFor="nombre">Nombre y apellidos*</label>
                  <Field
                    id="nombre"
                    name="nombre"
                    type="text"
                    value={isView ? quote.nombre : values.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.nombre && touched.nombre ? (
                  <p className="error text-danger ps-3">{errors.nombre}</p>
                ) : null}
              </div>

              <div className="col-sm-12 col-md-12 col-lg-3">
                <div className="form-group p-3">
                  <label htmlFor="nif">NIF*</label>
                  <Field
                    id="nif"
                    name="nif"
                    type="text"
                    value={isView ? quote.nif : values.nif}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.nif && touched.nif ? (
                  <p className="error text-danger ps-3">{errors.nif}</p>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="form-group p-3">
                  <label htmlFor="domicilio">Domicilio*</label>
                  <Field
                    id="domicilio"
                    name="domicilio"
                    type="text"
                    value={isView ? quote.domicilio : values.domicilio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.domicilio && touched.domicilio ? (
                  <p className="error text-danger ps-3">{errors.domicilio}</p>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group p-3">
                  <label htmlFor="poblacion">Población*</label>
                  <Field
                    id="poblacion"
                    name="poblacion"
                    type="text"
                    value={isView ? quote.poblacion : values.poblacion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.poblacion && touched.poblacion ? (
                  <p className="error text-danger ps-3">{errors.poblacion}</p>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
                <div className="form-group p-3">
                  <label htmlFor="provincia">Provincia*</label>
                  <Field
                    id="provincia"
                    name="provincia"
                    type="text"
                    value={isView ? quote.provincia : values.provincia}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.provincia && touched.provincia ? (
                  <p className="error text-danger ps-3">{errors.provincia}</p>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
                <div className="form-group p-3">
                  <label htmlFor="cp">Código postal*</label>
                  <Field
                    id="cp"
                    name="cp"
                    type="number"
                    value={isView ? quote.cp : values.cp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.cp && touched.cp ? (
                  <p className="error text-danger ps-3">{errors.cp}</p>
                ) : null}
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group p-3">
                  <label htmlFor="telefono1">Teléfonos*</label>
                  <label htmlFor="telefono2"></label>
                  <div className="input-group">
                    <span className="input-group-text">+34</span>
                    <Field
                      id="telefono1"
                      name="telefono1"
                      type="text"
                      className="form-control"
                      value={isView ? quote.telefono1 : values.telefono1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isView}
                    />
                    <Field
                      id="telefono2"
                      name="telefono2"
                      type="text"
                      className="form-control"
                      value={isView ? quote.telefono2 : values.telefono2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isView}
                    />
                  </div>
                </div>

                {errors.telefono1 && touched.telefono1 ? (
                  <p className="error text-danger ps-3">{errors.telefono1}</p>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group p-3">
                  <label htmlFor="email">Email*</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="off"
                    value={isView ? quote.email : values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.email && touched.email ? (
                  <p className="error text-danger ps-3">{errors.email}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="bg-primary bg-opacity-25 py-3 px-4 rounded-4 me-3 mb-3">
              <h2 className="lh-1 text fs-3 mb-0">
                Datos de la explotación a asegurar
              </h2>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="form-group p-3">
                  <label htmlFor="domicilioExplotacion">Domicilio*</label>
                  <Field
                    id="domicilioExplotacion"
                    name="domicilioExplotacion"
                    type="text"
                    value={
                      isView
                        ? quote.domicilioExplotacion
                        : values.domicilioExplotacion
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.domicilioExplotacion && touched.domicilioExplotacion ? (
                  <p className="error text-danger ps-3">
                    {errors.domicilioExplotacion}
                  </p>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3">
                <div className="form-group p-3 ">
                  <label htmlFor="construccion">Año de construcción*</label>
                  <Field
                    id="construccion"
                    name="construccion"
                    type="number"
                    value={isView ? quote.construccion : values.construccion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.construccion && touched.construccion ? (
                  <p className="error text-danger ps-3">
                    {errors.construccion}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="poblacionExplotacion">Población*</label>
                  <Field
                    id="poblacionExplotacion"
                    name="poblacionExplotacion"
                    type="text"
                    value={
                      isView
                        ? quote.poblacionExplotacion
                        : values.poblacionExplotacion
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.poblacionExplotacion && touched.poblacionExplotacion ? (
                  <p className="error text-danger ps-3">
                    {errors.poblacionExplotacion}
                  </p>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="provinciaExplotacion">Provincia*</label>
                  <Field
                    id="provinciaExplotacion"
                    name="provinciaExplotacion"
                    type="text"
                    value={
                      isView
                        ? quote.provinciaExplotacion
                        : values.provinciaExplotacion
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.provinciaExplotacion && touched.provinciaExplotacion ? (
                  <p className="error text-danger ps-3">
                    {errors.provinciaExplotacion}
                  </p>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="cpExplotacion">Código postal*</label>
                  <Field
                    id="cpExplotacion"
                    name="cpExplotacion"
                    type="number"
                    value={isView ? quote.cpExplotacion : values.cpExplotacion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.cpExplotacion && touched.cpExplotacion ? (
                  <p className="error text-danger ps-3">
                    {errors.cpExplotacion}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="superficieConstruida">
                    Superficie construida*
                  </label>
                  <div className="input-group ">
                    <Field
                      id="superficieConstruida"
                      name="superficieConstruida"
                      type="number"
                      value={
                        isView
                          ? quote.superficieConstruida
                          : values.superficieConstruida
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      disabled={isView}
                    />
                    <span className="input-group-text">
                      m<sup>2</sup>
                    </span>
                  </div>

                  {errors.superficieConstruida &&
                  touched.superficieConstruida ? (
                    <p className="error text-danger pt-3">
                      {errors.superficieConstruida}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="renovacionTuberias">
                    Fecha de renovación de las tuberías*
                  </label>
                  <Field
                    id="renovacionTuberias"
                    name="renovacionTuberias"
                    type="date"
                    value={
                      isView
                        ? quote.renovacionTuberias
                        : values.renovacionTuberias
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.renovacionTuberias && touched.renovacionTuberias ? (
                  <p className="error text-danger ps-3">
                    {errors.renovacionTuberias}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="propiedad">¿En propiedad o alquilado?*</label>
                  <Field
                    as="select"
                    className="form-select"
                    name="propiedad"
                    id="propiedad"
                    value={isView ? quote.propiedad : values.propiedad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isView}
                  >
                    <option>Selecciona una opción</option>
                    <option value="propiedad">Propiedad</option>
                    <option value="alquilado">Alquilado</option>
                  </Field>
                </div>

                {errors.propiedad && touched.propiedad ? (
                  <p className="error text-danger ps-3">{errors.propiedad}</p>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="facturacion">Facturación*</label>
                  <div className="input-group ">
                    <Field
                      id="facturacion"
                      name="facturacion"
                      type="number"
                      value={isView ? quote.facturacion : values.facturacion}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      disabled={isView}
                    />
                    <span className="input-group-text">€</span>
                  </div>

                  {errors.facturacion && touched.facturacion ? (
                    <p className="error text-danger pt-3">
                      {errors.facturacion}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="empleados">Número de empleados*</label>
                  <div className="input-group">
                    <Field
                      id="empleados"
                      name="empleados"
                      type="number"
                      value={isView ? quote.empleados : values.empleados}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      disabled={isView}
                    />
                    <span className="input-group-text">
                      <i className="bi bi-person"></i>
                    </span>
                  </div>

                  {errors.empleados && touched.empleados ? (
                    <p className="error text-danger pt-3">{errors.empleados}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="bg-primary bg-opacity-25 py-3 px-4 rounded-4 me-3 mt-4">
              <h2 className="lh-1 text fs-3 mb-0">
                Situación de la explotación
              </h2>
              <small className="text-muted">Seleccione una opción</small>
            </div>

            <div className="form-group mt-3 p-3 d-flex w-100 gap-4">
              <div className="form-check">
                <label className="form-check-label" htmlFor="despoblado">
                  Despoblado
                </label>
                <Field
                  className="form-check-input"
                  type="radio"
                  name="situacionExplotacion"
                  id="despoblado"
                  value="Despoblado"
                  checked={
                    isView
                      ? quote.situacionExplotacion === "Despoblado"
                      : values.situacionExplotacion === "Despoblado"
                  }
                  disabled={isView}
                />
              </div>
              <div className="form-check">
                <label className="form-check-label" htmlFor="nucleo-urbano">
                  Núcleo urbano
                </label>
                <Field
                  className="form-check-input"
                  type="radio"
                  name="situacionExplotacion"
                  id="nucleo-urbano"
                  value="Núcleo urbano"
                  checked={
                    isView
                      ? quote.situacionExplotacion === "Núcleo urbano"
                      : values.situacionExplotacion === "Núcleo urbano"
                  }
                  disabled={isView}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="bg-primary bg-opacity-25 py-3 px-4 rounded-4 me-3 mt-4">
              <h2 className="lh-1 text fs-3 mb-0">Clase de construcción</h2>
            </div>

            <div className="row mt-4">
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="cerramientos">
                    ¿Cerramientos o muros exteriores?*
                  </label>
                  <Field
                    as="select"
                    className="form-select"
                    name="cerramientos"
                    id="cerramientos"
                    value={isView ? quote.cerramientos : values.cerramientos}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isView}
                  >
                    <option>Selecciona una opción</option>
                    <option value="incombustible">Incombustible</option>
                    <option value="panelSandwich">Panel sandwich</option>
                    <option value="poliuretano">Poliuretano proyectado</option>
                    <option value="combustible">Combustible</option>
                  </Field>
                </div>

                {errors.cerramientos && touched.cerramientos ? (
                  <p className="error text-danger ps-3">
                    {errors.cerramientos}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="cubierta">Clase de cubierta*</label>
                  <Field
                    as="select"
                    className="form-select"
                    name="cubierta"
                    id="cubierta"
                    value={isView ? quote.cubierta : values.cubierta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isView}
                  >
                    <option>Selecciona una opción</option>
                    <option value="incombustible">Incombustible</option>
                    <option value="panelSandwich">Panel sandwich</option>
                    <option value="poliuretano">Poliuretano proyectado</option>
                    <option value="combustible">Combustible</option>
                  </Field>
                </div>

                {errors.cubierta && touched.cubierta ? (
                  <p className="error text-danger ps-3">{errors.cubierta}</p>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div
                className={`col-sm-12 col-md-12 ${
                  (quote.quimicos !== undefined || values.quimicos !== "") &&
                  (quote.quimicos !== undefined ||
                    values.quimicos !== "ninguno") &&
                  (quote.quimicos !== undefined ||
                    values.quimicos !== "Selecciona una opción")
                    ? "col-lg-10"
                    : "col-lg-12"
                }`}
              >
                <div className="form-group p-3">
                  <label htmlFor="quimicos">
                    Productos químicos inflamables*
                  </label>
                  <Field
                    as="select"
                    className="form-select"
                    name="quimicos"
                    id="quimicos"
                    value={isView ? quote.quimicos : values.quimicos}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isView}
                  >
                    <option>Selecciona una opción</option>
                    <option value="ninguno">Ninguno</option>
                    <option value="gasoil">Gasoil (litros)</option>
                    <option value="gasolina">Gasolina (litros)</option>
                    <option value="gases">
                      Gases licuados y botellas (kg)
                    </option>
                  </Field>
                </div>

                {errors.quimicos && touched.quimicos ? (
                  <p className="error text-danger ps-3">{errors.quimicos}</p>
                ) : null}
              </div>

              {(quote.quimicos !== undefined || values.quimicos !== "") &&
                (quote.quimicos !== undefined ||
                  values.quimicos !== "ninguno") &&
                (quote.quimicos !== undefined ||
                  values.quimicos !== "Selecciona una opción") && (
                  <div className="col-sm-12 col-md-12 col-lg-2">
                    <div className="form-group p-3">
                      <label htmlFor="cantidadQuimicos">Cantidad*</label>
                      <Field
                        className="form-control"
                        type="number"
                        name="cantidadQuimicos"
                        id="cantidadQuimicos"
                        value={
                          isView
                            ? quote.cantidadQuimicos
                            : values.cantidadQuimicos
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isView}
                      />
                    </div>

                    {errors.cantidadQuimicos && touched.cantidadQuimicos ? (
                      <p className="error text-danger ps-3">
                        {errors.cantidadQuimicos}
                      </p>
                    ) : null}
                  </div>
                )}
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg">
                <div className="form-group p-3">
                  <label htmlFor="proteccionIncendios">
                    Medidas de protección contra incendios*
                  </label>
                  <Field
                    as="select"
                    className="form-select"
                    name="proteccionIncendios"
                    id="proteccionIncendios"
                    value={
                      isView
                        ? quote.proteccionIncendios
                        : values.proteccionIncendios
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isView}
                  >
                    <option>Selecciona una opción</option>
                    <option value="extintores">Extintores</option>
                    <option value="bocasIncendios">Bocas de incendio</option>
                    <option value="hidratantes">Hidratantes</option>
                  </Field>
                </div>

                {errors.proteccionIncendios && touched.proteccionIncendios ? (
                  <p className="error text-danger ps-3">
                    {errors.proteccionIncendios}
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="bg-primary bg-opacity-25 py-3 px-4 rounded-4 me-3 mt-4">
              <h2 className="lh-1 text fs-3 mb-0">Tipo de explotación</h2>
              <small className="text-muted">
                Seleccione una opción* (Debe seleccionar al menos una opción)
              </small>
            </div>

            <div className="row">
              <div className="col">
                <div className="row form-group mt-3 p-3 d-flex w-100 gap-5">
                  <div className="col-lg-3 col-md-6 col-sm-6 form-check form-check-inline">
                    <label className="form-check-label" htmlFor="vacunoCarne">
                      <input
                        type="radio"
                        className="me-2"
                        name="vacunoCarne"
                        id="vacunoCarne"
                        value={
                          isView
                            ? quote.vacunoCarne
                            : initialOptions.vacunoCarne
                        }
                        onChange={() =>
                          updateSelectedOption(setFieldValue, "vacunoCarne")
                        }
                        checked={
                          isView
                            ? quote.vacunoCarne
                            : initialOptions.vacunoCarne
                        }
                        disabled={isView}
                      />
                      Vacuno carne
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 form-check form-check-inline">
                    <label className="form-check-label" htmlFor="vacunoLeche">
                      <input
                        type="radio"
                        className="me-2"
                        name="vacunoLeche"
                        id="vacunoLeche"
                        value={
                          isView
                            ? quote.vacunoLeche
                            : initialOptions.vacunoLeche
                        }
                        onChange={() =>
                          updateSelectedOption(setFieldValue, "vacunoLeche")
                        }
                        checked={
                          isView
                            ? quote.vacunoLeche
                            : initialOptions.vacunoLeche
                        }
                        disabled={isView}
                      />
                      Vacuno leche
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 form-check form-check-inline">
                    <label className="form-check-label" htmlFor="equino">
                      <input
                        type="radio"
                        className="me-2"
                        name="equino"
                        id="equino"
                        value={isView ? quote.equino : initialOptions.equino}
                        onChange={() =>
                          updateSelectedOption(setFieldValue, "equino")
                        }
                        checked={isView ? quote.equino : initialOptions.equino}
                        disabled={isView}
                      />
                      Equino
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 form-check form-check-inline">
                    <label className="form-check-label" htmlFor="ovinoCaprino">
                      <input
                        type="radio"
                        className="me-2"
                        name="ovinoCaprino"
                        id="ovinoCaprino"
                        value={
                          isView
                            ? quote.ovinoCaprino
                            : initialOptions.ovinoCaprino
                        }
                        onChange={() =>
                          updateSelectedOption(setFieldValue, "ovinoCaprino")
                        }
                        checked={
                          isView
                            ? quote.ovinoCaprino
                            : initialOptions.ovinoCaprino
                        }
                        disabled={isView}
                      />
                      Ovino y caprino
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 form-check form-check-inline">
                    <label className="form-check-label" htmlFor="porcino">
                      <input
                        type="radio"
                        className="me-2"
                        name="porcino"
                        id="porcino"
                        value={isView ? quote.porcino : initialOptions.porcino}
                        onChange={() =>
                          updateSelectedOption(setFieldValue, "porcino")
                        }
                        checked={
                          isView ? quote.porcino : initialOptions.porcino
                        }
                        disabled={isView}
                      />
                      Porcino
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 form-check form-check-inline">
                    <label className="form-check-label" htmlFor="avicola">
                      <input
                        type="radio"
                        className="me-2"
                        name="avicola"
                        id="avicola"
                        value={isView ? quote.avicola : initialOptions.avicola}
                        onChange={() =>
                          updateSelectedOption(setFieldValue, "avicola")
                        }
                        checked={
                          isView ? quote.avicola : initialOptions.avicola
                        }
                        disabled={isView}
                      />
                      Avícola
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 form-check form-check-inline">
                    <label className="form-check-label" htmlFor="otros">
                      <input
                        type="radio"
                        className="me-2"
                        name="otros"
                        id="otros"
                        value={isView ? quote.otros : initialOptions.otros}
                        onChange={() =>
                          updateSelectedOption(setFieldValue, "otros")
                        }
                        checked={isView ? quote.otros : initialOptions.otros}
                        disabled={isView}
                      />
                      Otros
                    </label>
                  </div>
                </div>

                {(initialOptions.equino || quote.equino) && (
                  <div className="form-group">
                    <select
                      className="form-select"
                      name="tipoEquino"
                      id="tipoEquino"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isView}
                      value={isView ? quote.tipoEquino : values.tipoEquino}
                    >
                      <option>Selecciona una opción</option>
                      <option value="noCarnica">
                        Dedicada a la cría de ganado de aptitud no cárnica
                      </option>
                      <option value="carnica">
                        Dedicada a la cría de ganado de aptitud cárnica
                      </option>
                    </select>
                  </div>
                )}

                {(initialOptions.ovinoCaprino || quote.ovinoCaprino) && (
                  <div className="form-group">
                    <select
                      className="form-select"
                      name="tipoOvinoCaprino"
                      id="tipoOvinoCaprino"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isView}
                      value={
                        isView
                          ? quote.tipoOvinoCaprino
                          : values.tipoOvinoCaprino
                      }
                    >
                      <option>Selecciona una opción</option>
                      <option value="noCarnica">
                        Dedicada a la cría de ganado de aptitud no cárnica
                      </option>
                      <option value="carnica">
                        Dedicada a la cría de ganado de aptitud cárnica
                      </option>
                    </select>
                  </div>
                )}

                {(initialOptions.porcino || quote.porcino) && (
                  <div className="form-group">
                    <select
                      className="form-select"
                      name="tipoPorcino"
                      id="tipoPorcino"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isView}
                      value={isView ? quote.tipoPorcino : values.tipoPorcino}
                    >
                      <option>Selecciona una opción</option>
                      <option value="cebo">
                        Destinada exclusivamente a la producción de lechones
                        para cebo
                      </option>
                      <option value="engorde">
                        Destinada exclusivamente al cebo o engorde de los
                        animales
                      </option>
                      <option value="ciclo">
                        De ciclo cerrado, destinada a la cría de lechones y su
                        posterior
                      </option>
                    </select>
                  </div>
                )}

                {(initialOptions.avicola || quote.avicola) && (
                  <div className="form-group">
                    <select
                      className="form-select"
                      name="tipoAvicola"
                      id="tipoAvicola"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isView}
                      value={isView ? quote.tipoAvicola : values.tipoAvicola}
                    >
                      <option>Selecciona una opción</option>
                      <option value="pollos">
                        Destinada al engorde de pollos o broilers
                      </option>
                      <option value="pavos">
                        Destinada al engorde de codornices, pavos, faisanes y
                        otras aves de corral
                      </option>
                    </select>
                  </div>
                )}

                {(initialOptions.otros || quote.vacuno) && (
                  <div className="form-group">
                    <input
                      type="text"
                      name="otrosTiposExplotacion"
                      id="otrosTiposExplotacion"
                      className="form-control"
                      placeholder="Escriba el tipo de explotación"
                      value={
                        isView
                          ? quote.otrosTiposExplotacion
                          : values.otrosTiposExplotacion
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isView}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="bg-primary bg-opacity-25 py-3 px-4 rounded-4 me-3 mt-4 mb-3">
              <h2 className="lh-1 text fs-3 mb-0">Capitales</h2>
              <small className="text-muted">
                Marca las opciones que correspondan
              </small>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="form-group p-3">
                  <label htmlFor="continente">
                    Continente (Naves e instalaciones)
                  </label>
                  <div className="input-group ">
                    <Field
                      id="continente"
                      name="continente"
                      type="number"
                      value={isView ? quote.continente : values.continente}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      disabled={isView}
                    />
                    <span className="input-group-text">€</span>
                  </div>
                </div>

                {errors.continente && touched.continente ? (
                  <p className="error text-danger ps-3">{errors.continente}</p>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3">
                <div className="form-group p-3">
                  <label htmlFor="mobiliario">Mobiliario/maquinaria</label>
                  <div className="input-group ">
                    <Field
                      id="mobiliario"
                      name="mobiliario"
                      type="number"
                      value={isView ? quote.mobiliario : values.mobiliario}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      disabled={isView}
                    />
                    <span className="input-group-text">€</span>
                  </div>
                </div>

                {errors.mobiliario && touched.mobiliario ? (
                  <p className="error text-danger ps-3">{errors.mobiliario}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg">
              <div className="form-group p-3">
                <label htmlFor="existencias">Existencias</label>
                <Field
                  id="existencias"
                  name="existencias"
                  type="number"
                  value={isView ? quote.existencias : values.existencias}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  disabled={isView}
                />
              </div>

              {errors.existencias && touched.existencias ? (
                <p className="error text-danger ps-3">{errors.existencias}</p>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-12 col-lg">
              <div className="form-group p-3">
                <label htmlFor="cabezas">Cabezas</label>
                <Field
                  id="cabezas"
                  name="cabezas"
                  type="number"
                  value={isView ? quote.cabezas : values.cabezas}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  disabled={isView}
                />
              </div>

              {errors.cabezas && touched.cabezas ? (
                <p className="error text-danger ps-3">{errors.cabezas}</p>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-12 col-lg">
              <div className="form-group p-3">
                <label htmlFor="otrasExistencias">Otras Existencias</label>
                <div className="input-group ">
                  <Field
                    id="otrasExistencias"
                    name="otrasExistencias"
                    type="number"
                    value={
                      isView ? quote.otrasExistencias : values.otrasExistencias
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                  <span className="input-group-text">€</span>
                </div>
              </div>

              {errors.otrasExistencias && touched.otrasExistencias ? (
                <p className="error text-danger ps-3">
                  {errors.otrasExistencias}
                </p>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-12 col-lg">
              <div className="form-group p-3">
                <label htmlFor="vehiculos">Vehículos</label>
                <div className="input-group">
                  <Field
                    id="vehiculos"
                    name="vehiculos"
                    type="number"
                    value={isView ? quote.vehiculos : values.vehiculos}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                  <span className="input-group-text">€</span>
                </div>
              </div>

              {errors.vehiculos && touched.vehiculos ? (
                <p className="error text-danger ps-3">{errors.vehiculos}</p>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h3 className="text-decoration-underline p-3 me-0 fs-5">
                Almiarajes, paja y forrajes secos
              </h3>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="form-group p-3">
                <label htmlFor="naveCerrada">En nave cerrada</label>
                <div className="input-group">
                  <Field
                    id="naveCerrada"
                    name="naveCerrada"
                    type="number"
                    value={isView ? quote.naveCerrada : values.naveCerrada}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                  <span className="input-group-text">€</span>
                </div>
              </div>

              {errors.naveCerrada && touched.naveCerrada ? (
                <p className="error text-danger ps-3">{errors.naveCerrada}</p>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="form-group p-3">
                <label htmlFor="naveAbierta">En nave abierta</label>
                <div className="input-group">
                  <Field
                    id="naveAbierta"
                    name="naveAbierta"
                    type="number"
                    value={isView ? quote.naveAbierta : values.naveAbierta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                  <span className="input-group-text">€</span>
                </div>
              </div>

              {errors.naveAbierta && touched.naveAbierta ? (
                <p className="error text-danger ps-3">{errors.naveAbierta}</p>
              ) : null}
            </div>
          </div>

          <div className="mt-4">
            <div className="bg-primary bg-opacity-25 py-3 px-4 rounded-4 me-3 mt-4">
              <h2 className="lh-1 text fs-3 mb-0">
                ¿Se incluye vivienda y mobiliario doméstico?
              </h2>
              <small className="text-muted">Rellena en caso afirmativo</small>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group p-3">
                  <label htmlFor="superficieConstruidaConVivienda">
                    Superficie construida
                  </label>
                  <div className="input-group ">
                    <Field
                      id="superficieConstruidaConVivienda"
                      name="superficieConstruidaConVivienda"
                      type="number"
                      value={
                        isView
                          ? quote.superficieConstruidaConVivienda
                          : values.superficieConstruidaConVivienda
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      disabled={isView}
                    />
                    <span className="input-group-text">
                      m<sup>2</sup>
                    </span>
                  </div>
                </div>

                {errors.superficieConstruidaConVivienda &&
                touched.superficieConstruidaConVivienda ? (
                  <p className="error text-danger ps-3">
                    {errors.superficieConstruidaConVivienda}
                  </p>
                ) : null}
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group p-3">
                  <label htmlFor="fechaConstruccion">Año de construcción</label>
                  <Field
                    id="fechaConstruccion"
                    name="fechaConstruccion"
                    type="number"
                    value={
                      isView
                        ? quote.fechaConstruccion
                        : values.fechaConstruccion
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    disabled={isView}
                  />
                </div>

                {errors.fechaConstruccion && touched.fechaConstruccion ? (
                  <p className="error text-danger ps-3">
                    {errors.fechaConstruccion}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group p-3">
                  <label htmlFor="continenteConVivienda">Continente</label>
                  <div className="input-group ">
                    <Field
                      id="continenteConVivienda"
                      name="continenteConVivienda"
                      type="number"
                      value={
                        isView
                          ? quote.continenteConVivienda
                          : values.continenteConVivienda
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      disabled={isView}
                    />
                    <span className="input-group-text">€</span>
                  </div>

                  {errors.continenteConVivienda &&
                  touched.continenteConVivienda ? (
                    <p className="error text-danger pt-3">
                      {errors.continenteConVivienda}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group p-3">
                  <label htmlFor="mobiliarioConVivienda">Mobiliario</label>
                  <div className="input-group">
                    <Field
                      id="mobiliarioConVivienda"
                      name="mobiliarioConVivienda"
                      type="number"
                      value={
                        isView
                          ? quote.mobiliarioConVivienda
                          : values.mobiliarioConVivienda
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      disabled={isView}
                    />
                    <span className="input-group-text">€</span>
                  </div>

                  {errors.mobiliarioConVivienda &&
                  touched.mobiliarioConVivienda ? (
                    <p className="error text-danger pt-3">
                      {errors.mobiliarioConVivienda}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="bg-primary bg-opacity-25 py-3 px-4 rounded-4 me-3 mt-4">
              <h2 className="lh-1 text fs-3 mb-0">
                Complementos y observaciones
              </h2>
            </div>

            <div className="row mt-3">

              <div className="col-12 p-3">
                <Field
                  as="textarea"
                  name="observaciones"
                  id="observaciones"
                  cols="30"
                  rows="10"
                  className="textarea form-control"
                  value={isView ? quote.observaciones : values.observaciones}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isView}
                />
              </div>

              {!isView && (
                <div className="col-12">
                  <div
                    {...getRootProps({
                      className:
                        "dropzone drop-container drop-container-custom d-flex flex-column justify-content-center align-items-center gap-4 border p-3 mb-4 rounded bg-secondary-subtle",
                    })}
                  >
                    <FileInputField
                      {...getInputProps({ name: "file", ref: fileInputRef })}
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                    />
                    {isDragActive ? (
                      <p>Suelta aquí tus archivos</p>
                    ) : (
                      <p>
                        Arrastra tus archivos hasta aquí, o haz click para
                        seleccionar un archivo
                      </p>
                    )}
                  </div>

                  <ErrorMessage
                    name="file"
                    component="div"
                    className="text-danger"
                  />

                  {acceptedFiles.map((fileItem, index) => (
                    <div key={index} className="file-item">
                      <div className="d-flex align-items-center">
                        <i className="bi bi-file-earmark"></i>
                        <p className="mb-0 ms-2">{fileItem.file.name}</p>
                        <button
                          className="btn btn-link"
                          onClick={() => removeFile(fileItem.file)}
                        >
                          <i className="bi bi-x"></i>
                        </button>
                      </div>
                    </div>
                  ))}

                  {isDragReject && (
                    <div className="alert alert-danger mt-4" role="alert">
                      El archivo que intentas subir no está permitido
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {!isView && (
            <button
              type="submit"
              className="btn btn-primary py-3 mt-4 w-100 rounded-4"
            >
              Solicitar cotización
            </button>
          )}

          <ToastContainer />

          {!isView && Object.keys(errors).length > 0 && (
            <p className="error text-danger pt-3">
              Algún campo del formulario tiene errores. Revíselo de nuevo.
            </p>
          )}

          {console.log(errors)}
        </Form>
      )}
    </Formik>
  );
};

export default QuoteForm;
