import React from 'react';
import PropTypes from 'prop-types';

const UserDetail = ({ user }) => {
    return (
        <div className="col-sm-12 col-md-12 col-lg-6">
            <div className='card p-4 mb-4'>

                <div className='d-flex align-items-center gap-2'>
                    <div className='d-flex align-items-center justify-content-center p-3 border rounded-circle' style={{width: '56px'}}>
                        <i className="bi bi-building"></i>
                    </div>
                    <div>
                        <h2 className='mb-0 fs-3'>{user?.email}</h2>
                        <em><small className='text-muted'>ID: { user?.id_empresa }</small></em>
                    </div>
                </div>

                <div className='d-flex flex-column'>
                    <div className='d-flex flex-column gap-1'>
                            <div className='d-flex'>
                                <p>{user?.nombre} {user?.apellidos}</p>
                            </div>

                            <div>
                                { user?.CIF && <p>CIF: {user?.CIF}</p> }
                                { user?.DNI && <p>DNI: {user?.DNI}</p> }
                            </div>
                    </div>

                    <div className='d-flex flex-column'>
                        <h3 className='fs-5 mb-3'>Contacto</h3>
                        { user?.telefono && (
                            <div className='d-flex gap-2 mb-2'>
                                <i className="bi bi-telephone"></i>
                                <a href={`tel:+34${user.telefono}`}>{user.telefono}</a>
                            </div>
                        ) }

                        { user?.email && (
                            <div className='d-flex gap-2'>
                                <i className="bi bi-envelope"></i>
                                <a href={`mailto:${user.email}`} className='mb-0'>{user.email}</a> 
                            </div>
                        )}
                    </div>
                    
                </div>

            </div>
        </div>
    );
}

UserDetail.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserDetail;
