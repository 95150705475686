import React from 'react';
import CreateEmailForm from '../../../components/pure/forms/CreateEmailForm';
import PropTypes from 'prop-types';

const CreateEmail = ({ onClose, mail, mode }) => {
  return (
    <div className="custom-modal d-flex p-4 position-absolute bottom-0 start-0 w-100 h-100 z-9">
      <div className="position-relative card border-0 rounded-4 bg-white w-100 align-self-end z-9">
        <CreateEmailForm onClose={onClose} mail={mail} mode={mode} />
      </div>
    </div>
  );
};

CreateEmail.propTypes = {
  onClose: PropTypes.func.isRequired,
  mail: PropTypes.object,
  send: PropTypes.func,
  resend: PropTypes.func,
  reply: PropTypes.func,
};

export default CreateEmail;
