import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosGlobal';
import UserDetail from './UserDetail';
import { useAuth } from '../../utils/AuthProvider';

const UsersList = () => {

    const [query, setQuery] = useState('')
    const [users, setUsers] = useState([])
    const [filteredResults, setFilteredResults] = useState(users);
    const [loading, setLoading] = useState(true)
    
    const { authState } = useAuth();

    useEffect(() => {

        setLoading(true);

        axiosInstance(`${process.env.REACT_APP_API_URI}api/users/all`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authState.token}` 
            },
        })
            .then(response => {
                setLoading(false)
                setUsers(response.data.users)
                setFilteredResults(
                    users.filter(user => user.DNI.toLowerCase().includes(query.toLowerCase()) || user.email.toLowerCase().includes(query.toLowerCase()))
                )
            }) 
        
    }, [query])

    const handleQuery = (e) => {
        setQuery(e.target.value)
    }


    return (
        <div className='col-sm-12 col-md-12 col-lg-9 p-4'>
            <div className='row align-items-end justify-content-between pb-4'>
                <div className='col-sm-12 col-md-9 col-lg-9 mb-4'>
                    <h1 className='mb-0 lh-1'>Usuarios</h1>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <input 
                        type="text" 
                        className='form-control rounded-3 p-3 border h-50'
                        placeholder='Filtrar'
                        value={query}
                        onChange={handleQuery}
                    />
                </div>
            </div>

            <div className='row'>
                {
                   loading ? 
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                   : users.length === 0 ? 
                        <div className="row justify-content-center">
                            <p className='mt-4 text-center'>No se encontraron resultados</p>
                        </div>
                        : query.length === 0
                            ? users.map((user) => {
                                return(
                                    <UserDetail user={user} key={user._id}/>
                                )
                            })
                            : filteredResults.map((user) => {
                                return(
                                    <UserDetail user={user} key={user._id} />
                                )
                            })

                }
            </div>

        </div>  

    );
}

export default UsersList;
