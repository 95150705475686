import React, { useState, useEffect } from 'react';
import { useAuth } from './utils/AuthProvider';
import AppRouter from './Routes/AppRoutes';
import Header from './components/containers/Header';
import Navbar from './components/containers/Navbar';
import LoginPage from './Pages/login/LoginPage';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/custom.css';

const App = () => {
  const { authState } = useAuth();

  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {

    authState && authState.user === null
      ? setIsLogged(false)
      : setIsLogged(true)

  }, [authState])

  return (
    <>
      {isLogged ?
        <div className='app d-flex flex-wrap postion-relative'>
            <Header />
            <main className='container-fluid px-0 overflow-x-hidden'>
              <div className="row">
                <Navbar />
                <AppRouter />
              </div>
            </main>
        </div>
        :
        <div className='app row justify-content-center align-items-center' style={{ minHeight: '100vh', backgroundColor: '#f8f9fa' }}>
          <LoginPage />
        </div>
      }
    </>
  )
};

export default App;