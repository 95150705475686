import React from 'react';
import LoginForm from '../../components/pure/forms/LoginForm';
import Container from '../../Layouts/Container'


const LoginPage = () => {
    return (
        <div className='col-sm-12 col-md-12 col-lg-6 col-xl-4 d-flex flex-column justify-content-center'>

            <img 
                src="/assets/images/logo-main.svg" 
                alt="Logo main" 
                className='img-fluid mx-auto'
                style={{maxWidth: '300px', margin: '0 auto'}}
            />
            
            <LoginForm />

        </div>
    );
};

export default LoginPage;