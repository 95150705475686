import React from 'react';
import UsersList from './UsersList';
import Container from '../../Layouts/Container';


const UsersPage = () => {
    return (
        <UsersList />
    );
};

export default UsersPage;
