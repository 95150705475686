import { useEffect } from "react"; 
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const AxiosInterceptor = ({ children }) => {

    const navigate = useNavigate();
    const { dispatch } = useAuth();

    useEffect(() => {
        
        const resInterceptor = response => {
            return response;
        }

        const errInterceptor = error => {

            if (error.response.status === 400) {
                dispatch({ type: "LOGOUT" });

                toast.error('La sesión ha caducado, por favor inicial sesión de nuevo', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }

            return Promise.reject(error);
        }

        const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);

        return () => instance.interceptors.response.eject(interceptor);

    }, [navigate, dispatch])
    return children;

}

export default instance;
export { AxiosInterceptor };
