import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../utils/AuthProvider';



const HamburguerMenu = () => {

    const { dispatch } = useAuth();

    const handleLogout = () => {
        dispatch({
            type: 'LOGOUT'
        })
    }

    return (
       <>
            <button className="hamburguer-menu dropdown-toggle avatar rounded-circle border bg-secondary-slate bg-opacity-10" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className='bi bi-list fs-4'></i>
            </button>

            <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="buzon">Buzón de notificaciones</Link></li>
                <li><Link className="dropdown-item" to="solicitud-de-cotizaciones">Solicitud de cotizaciones</Link></li>
                <li><Link className="dropdown-item" to="gestion-documental">Gestión de documentos</Link></li>
                <li><Link className="dropdown-item" to="gestion-documental/subir-archivo">Subir un nuevo documento</Link></li>
                <li><Link className="dropdown-item" to="gestion-documental/documentos">Documentos</Link></li>
                <li><Link className="dropdown-item" to="usuarios">Usuarios</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item" href="#" onClick={handleLogout}>Cerrar sesión</a></li>
            </ul>

        </>
    );
}

export default HamburguerMenu;
