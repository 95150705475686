import EmailHeader from "./components/EmailHeader";
import EmailItem from "./components/EmailItem";
import EmailModal from "./components/EmailModal";
import CreateEmail from "./components/CreateEmail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseEmail from "../../hooks/useEmail";
import { useEffect, useRef } from "react";
import ModalPortal from "./components/EmailModalPortal";
import PaginationCustom from "../../components/pure/Pagination-custom";

const EmailList = ({ handleLogged }) => {
  const {
    loadData,
    mails,
    selectedMail,
    setSelectedMail,
    isLoading,
    currentPage,
    setPageTokens,
    tabs,
    setTabs,
    params,
    setParams,
    markAsRead,
    deleteMail,
    isDetailOpen,
    setIsDetailOpen,
    setNewEmailOpen,
    newMailOpen,
    handleSearchEmail,
    nextPage,
    previousPage,
    invalidToken,
    setCurrentPage,
    totalPages,
  } = UseEmail();

  useEffect(() => {
    if (invalidToken) handleLogged();
  }, [invalidToken]);

  const emailListRef = useRef(null);

  useEffect(() => {
    // Vuelve a desplazar al elemento deseado después de cada renderizado
    if (emailListRef.current) {
      emailListRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  });

  const openModal = (mail) => {
    setSelectedMail(mail);
    setIsDetailOpen(true);
  };

  const closeModal = () => {
    setSelectedMail(null);
    setIsDetailOpen(false);
    setNewEmailOpen(false);
  };

  const openCreateEmail = () => {
    setNewEmailOpen(true);
  };

  const dispatchSearch = (value) => {
    handleSearchEmail(value);
  };

  return (
    <div ref={emailListRef}>
      <EmailHeader
        handleLogged={handleLogged}
        createEmail={openCreateEmail}
        refreshInbox={() => loadData(params)}
        pageNumber={currentPage}
        nextPage={nextPage}
        previousPage={previousPage}
        searchTerm={dispatchSearch}
      />

      {!isLoading && (
        <ul className="nav nav-tabs mt-4">
          <li className="nav-item">
            <span
              className={`nav-link ${tabs.inbox ? "active" : ""}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setParams((prevParams) => ({
                  ...prevParams,
                  labels: "INBOX",
                }));
                setPageTokens("");
                setTabs({ ...tabs, inbox: true, sent: false, trash: false });
              }}
            >
              <i className="bi bi-inbox me-2"></i>
              Bandeja de entrada
            </span>
          </li>
          <li className="nav-item">
            <span
              className={`nav-link ${tabs.sent ? "active" : ""}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setParams((prevParams) => ({
                  ...prevParams,
                  labels: "SENT",
                }));
                setPageTokens("");
                setTabs({ ...tabs, inbox: false, sent: true, trash: false });
              }}
            >
              <i className="bi bi-send me-2"></i>
              Enviados
            </span>
          </li>
          <li className="nav-item">
            <span
              className={`nav-link ${tabs.trash ? "active" : ""}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setParams((prevParams) => ({
                  ...prevParams,
                  labels: "TRASH",
                }));
                setPageTokens("");
                setTabs({ ...tabs, inbox: false, sent: false, trash: true });
              }}
            >
              <i className="bi bi-trash me-2"></i>
              Eliminados
            </span>
          </li>
        </ul>
      )}

      <section className="email-list position-relative">
        {isLoading ? (
          <div
            className="spinner-border position-absolute top-50 start-50"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : mails && mails.length > 0 ? (
          mails.map((mail) => (
            <>
              {mail && (
                <EmailItem
                  key={mail?.emailData?.id}
                  mail={mail}
                  onOpen={() => {
                    openModal(mail);
                    markAsRead(mail, 1);
                  }}
                  changeStatus={markAsRead}
                  deleteMail={deleteMail}
                />
              )}
            </>
          ))
        ) : (
          <p className="text-center mt-5">
            No se encontraron correos en esta bandeja
          </p>
        )}
      </section>

      <div className="d-flex justify-content-end align-items-center gap-3 mt-4">
        {!isLoading && (
          <PaginationCustom
            getList={loadData}
            filters={params}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        )}
      </div>

      {/* Renderiza el modal dentro del portal */}
      <ModalPortal>
        {isDetailOpen && selectedMail && (
          <EmailModal mail={selectedMail} onClose={closeModal} />
        )}
        {newMailOpen && <CreateEmail onClose={closeModal} />}
      </ModalPortal>

      <ToastContainer />
    </div>
  );
};

export default EmailList;
