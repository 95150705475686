import { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthProvider';
import axiosInstance from "../utils/axiosGlobal";
import formatRelativeWithOptions from 'date-fns/esm/fp/formatRelativeWithOptions/index.js';


const UseEmail = () => {

    const apiUrl = process.env.REACT_APP_API_URI;
    const { authState } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [pageTokens, setPageTokens] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageTokenPosition, setPageTokenPosition] = useState(0);
    const [mails, setMails] = useState([]);
    const [invalidToken, setInvalidToken] = useState(false);
    const [selectedMail, setSelectedMail] = useState(null);
    const [tabs, setTabs] = useState({
        inbox: true,
        sent: false,
        trash: false
    });
    const [query, setQuery] = useState('');
    const [params, setParams] = useState({
        labels: 'INBOX',
        page: 1,
        maxResults: 20,
        query: query
    })
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [newMailOpen, setNewEmailOpen] = useState(false);
    const [toggleSearch, setToggleSearch] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        loadData(params);
    }, [params]);

    useEffect(() => {
        setParams({
            ...params,
            query: query
        })
    }, [query])

    const loadData = async (params) => {
        setIsLoading(true);

        try {
            const response = await axiosInstance(`${apiUrl}api/mails/all`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authState.token}`,
                },
                data: {
                    "at": authState.googleCredential.at,
                    "mail": authState.googleCredential.email,
                    "nextPageToken": pageTokens[params.page - 1] || '',
                    "maxResults": params.maxResults,
                    "labels": params.labels,
                    "query": params.query
                },
            });

            if (response.status === 200) {
                setIsLoading(false);
                setMails(response.data.messages);
                setTotalPages(Math.ceil(response.data.total / 20));

                let aux = [...pageTokens];

                aux[params.page] = response.data.nextPageToken;

                setPageTokens(aux);
            } else if (response.status === 201) {
                setInvalidToken(true)
            }

        } catch (error) {
            console.log(error.message);
        }
    };


    const markAsRead = (element, value) => {
        axiosInstance
            .post(`${apiUrl}api/mails/mark-read`, {
                at: authState.googleCredential.at,
                mail: authState.googleCredential.email,
                messageId: element.emailData.id,
                markAsRead: value,
                maxResults: params?.maxResults,
                labels: params?.labels,
                query: params?.query
            },
                {
                    headers: {
                        Authorization: `Bearer ${authState.token}`,
                    },
                })
            .then(() => loadData(params))
            .catch((error) => console.log(error.message));
    };


    const deleteMail = (mail) => {
        console.log(mail)
        axiosInstance(`${apiUrl}api/mails/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authState.token}`,
            },
            data: {
                "at": authState.googleCredential.at,
                "mail": authState.googleCredential.email,
                "messageId": mail.emailData.id,
            },
        })
            .then((response) => {

                if (response?.status === 200) {
                    setIsLoading(false);
                    loadData(params);
                }

            })
            .catch((error) => console.log(error.message));
    }

    const handleSearchEmail = async (value) => {
        try {
            setQuery(value);
            setParams({
                ...params,
                query: value
            });
            setIsLoading(true);

            const response = await axiosInstance(`${apiUrl}api/mails/all`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authState.token}`,
                },
                data: {
                    "at": authState.googleCredential.at,
                    "mail": authState.googleCredential.email,
                    "maxResults": params?.maxResults,
                    "labels": params?.labels,
                    "query": value
                }
            });

            if (response?.status === 200) {
                setMails(response.data.messages);
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const nextPage = () => {

        setParams({
            ...params,
            page: params.page + 1
        })

    }

    const previousPage = () => {

        if (params.page === 1) {
            return;
        }

        params.page >= 0 &&
            setParams({
                ...params,
                page: params.page - 1
            })

    }

    return {
        authState,
        loadData,
        mails,
        setMails,
        selectedMail,
        setSelectedMail,
        isLoading,
        setIsLoading,
        currentPage,
        setCurrentPage,
        pageTokens,
        setPageTokens,
        tabs,
        setTabs,
        params,
        setParams,
        markAsRead,
        deleteMail,
        isDetailOpen,
        setIsDetailOpen,
        newMailOpen,
        setNewEmailOpen,
        pageTokenPosition,
        setPageTokenPosition,
        toggleSearch,
        setToggleSearch,
        query,
        setQuery,
        handleSearchEmail,
        nextPage,
        previousPage,
        invalidToken,
        totalPages,
        setTotalPages
    }
}

export default UseEmail;
