import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};


const AuthVerify = (props) => {
  let location = useLocation();
  const user = JSON.parse(localStorage.getItem("authUser"));

  const expirationTime = 74 * 60 * 60 * 1000;

  useEffect(() => {
    if (user && user.token) {
      const decodedJwt = parseJwt(user.token);

      if (decodedJwt && decodedJwt.exp * 1000 > Date.now() + expirationTime) {
        props.logOut();
      }
    }
  }, [location, props, user, expirationTime]);

  
  return null;
};

export default AuthVerify;
