import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../utils/AuthProvider";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const loginValidation = Yup.object().shape({
  email: Yup.string()
    .required("El campo email es obligatorio")
    .email("El formato de email no es correcto"),
  password: Yup.string()
    .required("El campo contraseña es obligatorio")
    .min(8, "La contraseña debe tener al menos ocho caracteres"),
});

//formulario de acceso
const LoginForm = () => {
  const { dispatch, authState } = useAuth();

  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <Formik
      validationSchema={loginValidation}
      initialValues={{ email: "", password: "" }}
      onSubmit={(values) => {
        axios(`${process.env.REACT_APP_API_URI}api/login`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authState.token}`,
          },
          data: { email: values.email, password: values.password },
        })
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "SET_USER",
                payload: {
                  user: values.email,
                  roles: response?.data?.roles,
                  token: response?.data?.token,
                  googleCredential: null,
                },
              });
              setLoggedIn(true);
              toast.success("¡Bien! Has hecho login con éxito", {
                position: "top-right",
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            toast.error(
              "Hay error en alguno de los campos, inténtalo de nuevo",
              {
                position: "top-right",
              }
            );
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form
          className="login-form mx-4 my-5 card p-5 rounded-4"
          noValidate
          onSubmit={handleSubmit}
        >
          <h2 className="mb-4">Ingresa en la plataforma</h2>

          <Field
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Email"
            className="form-control inp_text"
            id="email"
          />

          <p className="error text-danger">
            {errors.email && touched.email && errors.email}
          </p>

          <Field
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            placeholder="Contraseña"
            className="form-control"
          />

          <p className="error text-danger">
            {errors.password && touched.password && errors.password}
          </p>

          <button className="btn btn-primary p-3" type="submit">
            Acceder
          </button>

          {/* <p className='text-center m-0'> 
                            ¿Has olvidado la contraseña? <a href='#' className='text-muted'>Envíame un enlace para restablecerla.</a>
                        </p> */}

          <ToastContainer />
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
