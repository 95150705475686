import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../utils/AuthProvider";
import axiosInstance from "../../../utils/axiosGlobal";
import UseHandleEmail from "../../../hooks/useHandleEmail";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDropzone } from "react-dropzone";

const FileInputField = React.forwardRef((props, ref) => (
  <input type="file" ref={ref} {...props} />
));

const validationSchema = Yup.object().shape({
  to: Yup.string()
    .email("Formato de correo no válido")
    .required("Es obligatorio indicar un destinatario"),
  cc: Yup.string().email("Formato de correo no válido"),
  subject: Yup.string(),
  body: Yup.string(),
});

const CreateEmailForm = ({ onClose = false, mail = {}, mode }) => {
  const { authState } = useAuth();
  const extractEmailFromHeader = UseHandleEmail();
  const fileInputRef = useRef(null);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const reader = new FileReader();

  //funcion para arrastrar los documentos adjuntos
  const onDrop = (files) => {
    files.map(async (file) => {
      const base64 = await convertBase64(file);
      setAcceptedFiles((prevFiles) => [
        ...prevFiles,
        { content: base64, name: file.name, type: file.type },
      ]);
    });
  };

  //pasar un adjunto a base 64 para poder trabajar con cualquier tipo de archivo (se pude limitar)
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file); // Leer como ArrayBuffer
      reader.onload = () => {
        const binaryString = new Uint8Array(reader.result).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        );
        const base64 = btoa(binaryString); // Convertir a base64 sin prefijo
        resolve(base64);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({ onDrop });

  const removeFile = (fileItem) => {
    setAcceptedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileItem)
    );
  };

  useEffect(() => {
    console.log("acceptedFiles changed:", acceptedFiles);
  }, [acceptedFiles]);

  const obtenerCorreo = (cadena) => {
    const correoRegex = /<([^>]+)>/;
    const resultado = cadena?.match(correoRegex);
    return resultado ? resultado[1] : null;
  };

  //asignamos los diferentes atributos del correo dependiendo de si estamos enviando, reenviando o respondiendo
  const [initialValues, setInitialValues] = useState({
    mail:
      mode !== undefined && mode.resend
        ? authState?.googleCredential?.email || ""
        : mode !== undefined && mail?.emailData?.headers
        ? mail?.emailData?.headers.from
        : "",
    to:
      mode !== undefined
        ? mode?.reply
          ? obtenerCorreo(mail?.emailData?.headers?.from)
          : mode?.resend
          ? ""
          : obtenerCorreo(mail?.emailData?.headers?.to)
        : "",
    cc:
      mode !== undefined
        ? mode?.reply
          ? obtenerCorreo(mail?.emailData?.headers?.cc)
            ? obtenerCorreo(mail?.emailData?.headers?.cc)
            : ""
          : ""
        : "",
    subject:
      mode !== undefined
        ? mail?.emailData?.headers.subject
          ? mail?.emailData?.headers.subject
          : ""
        : "",
    body: mode !== undefined ? (mode?.resend ? "" : "") : "",
    attachments: [],
    at: authState.googleCredential.at,
    messageId: mode !== undefined ? mail.emailData.id : "",
  });

  //manejador del envio del formulario
  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    if (mode?.reply) {
      values.mail = authState.googleCredential.email;
    }

    formData.append("values", values);
    formData.append("files", acceptedFiles);

    values.files = acceptedFiles;

    const sendType = mode?.reply ? "reply" : mode?.resend ? "forward" : "send";

    axiosInstance
      .post(`${process.env.REACT_APP_API_URI}api/mails/${sendType}`, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authState.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Email enviado con éxito", {
            position: "top-right",
          });
          resetForm();
          onClose();
        }
      })
      .catch((error) => {
        toast.error("Error al enviar el email", {
          position: "top-right",
        });
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
      }) => (
        <Form>
          <div className="d-flex flex-row-reverse bg-secondary rounded-top-4 p-2">
            <button
              type="button"
              className="btn-close close-btn"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>

          <div className="p-3 pt-0">
            <div className="pt-3 gap-2">
              <div className="mail-header d-flex border-bottom gap-5">
                <div className="d-flex py-3 gap-2">
                  <label htmlFor="to" className="form-label mb-0">
                    Para:{" "}
                  </label>
                  <Field
                    type="text"
                    id="to"
                    name="to"
                    placeholder="correo@email.com"
                    className={`text-secondary border-0 ${
                      errors.to && touched.to ? "non-validate" : ""
                    }`}
                    style={{ outline: 0 }}
                    value={values?.to}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="d-flex py-3 gap-2">
                  <label htmlFor="cc" className="form-label mb-0">
                    Cc:{" "}
                  </label>
                  <Field
                    type="text"
                    id="cc"
                    name="cc"
                    placeholder="correo@email.com"
                    className={`text-secondary border-0 ${
                      errors.cc && touched.cc ? "non-validate" : ""
                    }`}
                    style={{ outline: 0 }}
                    value={values?.cc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="d-flex border-bottom py-3 gap-2">
                <label htmlFor="subject" className="form-label mb-0">
                  Asunto:{" "}
                </label>
                <Field
                  type="text"
                  id="subject"
                  name="subject"
                  className="text-secondary border-0 w-100"
                  style={{ outline: 0 }}
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>

          <div className="text-area p-3 pt-0">
            <Field
              id="body"
              name="body"
              as="textarea"
              className="text-secondary border-0 w-100 overflow-y-scroll border-bottom"
              placeholder="Mensaje:"
              rows={6}
              style={{ outline: 0 }}
              value={values.body}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className="d-flex justify-content-between align-items-end flex-row-reverse p-3 pt-0 gap-3 w-100">
            <button
              type="submit"
              className="btn btn-primary rounded-3 h-25"
              style={{ flexBasis: "20%", marginBottom: "0.5em" }}
            >
              Enviar
            </button>
            <div
              className="d-flex gap-2 flex-column"
              style={{ flexBasis: "80%" }}
            >
              <div
                {...getRootProps({
                  className:
                    "dropzone d-flex flex-column justify-content-center align-items-center gap-4 border p-3 rounded bg-secondary-subtle",
                })}
              >
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <div>
                    <FileInputField
                      {...getInputProps({ name: "file", ref: fileInputRef })}
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                    />
                    {isDragActive ? (
                      <p className="mb-0">Suelta aquí tus archivos</p>
                    ) : (
                      <p className="mb-0">
                        Arrastra tus archivos hasta aquí, o haz click para
                        seleccionar un archivo
                      </p>
                    )}
                  </div>

                  <ErrorMessage
                    name="file"
                    component="div"
                    className="text-danger"
                  />

                  {isDragReject && (
                    <div className="alert alert-danger mt-4" role="alert">
                      El archivo que intentas subir no está permitido
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex">
                {acceptedFiles.map((fileItem, index) => (
                  <div key={index} className="file-item flex-basis-100 z-9">
                    <div className="d-flex align-items-center">
                      <i className="bi bi-file-earmark"></i>
                      <p className="mb-0 ms-2">{fileItem.name}</p>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => removeFile(fileItem)}
                      >
                        <i className="bi bi-x"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateEmailForm;
