import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import Container from '../../Layouts/Container';
import InfoCard from '../../components/pure/InfoCard'


const DocumentsPage = () => {
    return ( 
            <div className="col-sm-12 col-md-12 col-lg-9 p-4">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-5 mb-4">
                        <InfoCard 
                            link={'subir-archivo'} 
                            icon={'bi bi-upload'} 
                            title={ 'Subir un nuevo documento' } 
                            text={ 'Sube tus archivos a la app' } 
                            external={false}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-5 mb-4">
                        <InfoCard 
                            link={'documentos'} 
                            icon={'bi bi-card-list'} 
                            title={ 'Documentos' } 
                            text={ 'Consulta el listado y descarga los documentos que necesites' } 
                            external={false}
                        />
                    </div>
                </div>
                <Outlet />
            </div>

    );
};

export default DocumentsPage;
