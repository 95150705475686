import React, { createContext, useContext, useReducer, useEffect } from "react";

const AuthContext = createContext();
const RoleContext = createContext();

const SET_USER = "SET_USER";
const LOGOUT = "LOGOUT";
const LOGOUT_GOOGLE = "LOGOUT_GOOGLE";

const authReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
        roles: action.payload.roles,
        token: action.payload.token,
        googleCredential: action.payload.googleCredential,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        roles: [],
        token: "",
        googleCredential: null,
      };
    case LOGOUT_GOOGLE:
      return { ...state, googleCredential: null };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, {
    user: null,
    roles: [],
    token: "",
    googleCredential: null,
  });

  useEffect(() => {
    if (localStorage.getItem("authUser") !== null) {
      const storedUser = JSON.parse(localStorage.getItem("authUser"));
      if (storedUser.user !== null)
        dispatch({
          type: "SET_USER",
          payload: {
            user: storedUser.user,
            roles: storedUser.roles,
            token: storedUser.token,
            googleCredential: storedUser.googleCredential,
          },
        });
    }
  }, []);

  useEffect(() => {
    if (authState && authState.user !== null) {
      localStorage.setItem("authUser", JSON.stringify(authState));
    } else {
      localStorage.removeItem("authUser");
    }
  }, [authState]);

  return (
    <AuthContext.Provider value={{ authState, dispatch }}>
      <RoleContext.Provider value={ authState.roles }>
        {children}
      </RoleContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const c = useContext(AuthContext) ?? null;
  return c;
};

export const useRoles = () => {
  const roles = useContext(RoleContext) ?? [];
  return roles;
};