import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const MenuItem = ({ link, text, icon, hasChildren, children }) => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  const current = hasChildren
    ? "current bg-body-secondary p-3 rounded has-children"
    : "current bg-body-secondary p-3 rounded";
  const notCurrent = hasChildren ? "p-3 rounded has-children" : "p-3 rounded";

  const itemActive = isActive
    ? "sub-menu d-flex flex-column mb-0 ps-3 active"
    : "sub-menu d-flex flex-column mb-0 ps-3";

  const handleActive = () => {
    setIsActive(!isActive);
  };

  return (
    <li className={location.pathname === link ? current : notCurrent}>
      <div className="d-flex align-items-center gap-3" onClick={handleActive}>
        <i className={`${icon} fs-4`}></i>
        <Link to={link}>{text}</Link>
      </div>

      {hasChildren && (
        <ul
          className={itemActive}
          style={
            isActive
              ? {
                  maxHeight: "700px",
                  transition: "max-height 0.3s ease-in-out",
                }
              : {
                  maxHeight: "0",
                  overflow: "hidden",
                  transition: "max-height 0.3s ease-in-out",
                }
          }
        >
          {children}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
