import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import base64url from 'base64url';
import { useAuth } from '../../../utils/AuthProvider';
import axiosInstance from '../../../utils/axiosGlobal';
import CreateEmail from './CreateEmail';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { saveAs } from 'file-saver';

const EmailModal = ({ mail, onClose }) => {

  const { authState } = useAuth();
  const [modalReenviar, setModalReenviar] = useState(false);
  const [archivosAdjuntos, setArchivosAdjuntos] = useState([]);
  const [mode, setMode] = useState({
    reply: false,
    resend: false,
  })
  const [imgsHtml, setImgsHtml] = useState([]);
  const [modifiedHtml, setModifiedHtml] = useState(mail?.emailData?.textHtml ?? mail?.emailData?.textPlain);

  useEffect(() => {
    if (mail && mail.emailData && mail.emailData.attachments) {
      const inlineAttachments = mail.emailData.attachments.filter(att => att.headers && att.headers['content-id']);
      inlineAttachments.forEach(getAttachment);
    }
  }, [mail]);

  const attachments = mail.emailData.attachments ?? [];

  const filteredAttachments = attachments.filter(att => !att.headers["content-id"]);

  let from = mail.emailData.headers.from
  let subject = mail.emailData.headers.subject
  const cc = mail.emailData.headers.cc ?? '';

  // MANEJAMOS TODAS LAS VARIANTES DE EEVEE

  /* switch (mail.detalle.payload.mimeType) {
    case 'text/plain':

      body = <div dangerouslySetInnerHTML={{ __html: base64url.decode(mail?.detalle?.payload?.body?.data) }} />
      break;

    case 'text/html':

      body = <div dangerouslySetInnerHTML={{ __html: base64url.decode(mail?.detalle?.payload?.body?.data) }} />
      break;

    case 'multipart/alternative':
      body = mail.detalle.payload.parts.map((part) => {

        if (part.mimeType === 'text/html') {
          return (
            <div key={part.partId} dangerouslySetInnerHTML={{ __html: base64url.decode(part.body.data) }} />
          )
        }

        return body;
        
      });
      break;

    case 'multipart/related':
      body = mail.detalle.payload.parts.map((part) => {

        if (part.mimeType.includes('multipart')) {

          part.parts.map((element) => {

            if (element.type = 'text/html') {
              body = <div key={element.partId} dangerouslySetInnerHTML={{ __html: base64url.decode(element.body.data) }} />
            }

          })

          return body;
        } else { return null; }

      })
      break;

    case 'multipart/mixed':
      body = mail.detalle.payload.parts.map((part) => {

        if(part.mimeType === 'text/plain') {
          return <div key={part.partId} dangerouslySetInnerHTML={{ __html: base64url.decode(part.body.data) }} />
        } else { 
          return;
        }

      });
      break;
  
    default:
      break;
  } */

  const dateObject = new Date(mail.emailData.headers.date);
  const formattedDate = format(dateObject, "EEEE, dd-MM-yyyy, 'a las' HH:mm", { locale: es });

  const getAttachment = (attachment) => {
    axiosInstance
      .get(
        `https://gmail.googleapis.com/gmail/v1/users/${authState.googleCredential.email}/messages/${mail.emailData.id}/attachments/${attachment.attachmentId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authState.googleCredential.at}`,
          },
        }
      )
      .then((response) => {

        if (response.status === 200) {
          const mimeType = attachment.mimeType;

          if (attachment.headers["content-id"]) {

            const cid = attachment.headers["content-id"].replace(/[<>]/g, '');
            const dataBase64 = Buffer.from(response.data.data, 'base64');
            const dataUri = `data:${mimeType};base64,${dataBase64.toString('base64')}`;
            setModifiedHtml(prevHtml => prevHtml.replace(new RegExp(`cid:${cid}`, 'g'), dataUri));

          } else {

            const dataBase64 = Buffer.from(response.data.data, 'base64');
            const blobObj = new Blob([dataBase64], { type: attachment.mimeType });
            const blobUrl = URL.createObjectURL(blobObj);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = attachment.filename;
            link.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
            
          }
        } else {
          console.log('La respuesta del servidor no es válida.');
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const send = (values) => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_URI}api/mails/send`, values, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authState.token}`,
        },
      }).then(() => {
        toast.success('Email enviado con éxito', {
          position: 'top-right',
        })
        onClose();
      }).catch((error) => {
        console.log(error.message);
        toast.error('Error al enviar el email', {
          position: 'top-right',
        })
      })
  };

  const resend = () => {
    setModalReenviar(true);
    setMode({ ...mode, resend: true });
  };

  const reply = () => {
    setModalReenviar(true);
    setMode({ ...mode, reply: true });
  };

  return (
    <div className="custom-modal d-flex p-4 position-absolute bottom-0 start-0 w-100 h-100 z-4">
      {console.log(mail)}
      {!modalReenviar ? (
        <div className="email position-relative mh-100 card border-0 rounded-4 pb-5 bg-white w-100 align-self-end z-4">
          <div className="d-flex justify-content-between bg-secondary rounded-top-4 p-2">

            <small className="text-white ps-2">{formattedDate}</small>

            <button
              type="button"
              className="btn-close close-btn"
              aria-label="Close"
              onClick={onClose}
              onBlur={onClose}
            ></button>
          </div>

          <div className="p-3 pt-0">
            <div className="mail-header details pt-3 gap-2">
              <div className="d-flex border-bottom gap-5">
                <div className="d-flex py-3 gap-2">
                  <p className="mb-0">De: </p>
                  <span className="text-secondary">{from}</span>
                </div>

                <div className="d-flex py-3 gap-2">
                  <p className="mb-0">Cc: </p>
                  <span className="text-secondary">{cc}</span>
                </div>
              </div>

              <div className="d-flex border-bottom py-3 gap-2">
                <p className="mb-0">Asunto: </p>
                <span className="text-secondary">{subject}</span>
              </div>
            </div>
          </div>

          <div className="d-flex p-3 pt-0 gap-2 ">
            {filteredAttachments && filteredAttachments > 0 && (
              <p className="mb-0">Archivos adjuntos: </p>
            )}
            {filteredAttachments && filteredAttachments.map((attachment, index) => (
              <p
                key={index}
                className="text-secondary"
                style={{ cursor: 'pointer' }}
                onClick={() => getAttachment(attachment)}
              >
                {attachment.filename}
              </p>
            ))}
          </div>

          <div className="p-3 mb-3 pt-0 overflow-y-scroll">

            <div dangerouslySetInnerHTML={{ __html: modifiedHtml }} />

          </div>

          <div className="position-absolute end-0 bottom-0 d-flex flex-row-reverse p-3 gap-3 bg-white w-100 rounded-bottom-4" style={{ boxShadow: '0 -3px 20px -15px rgba(0, 0, 0, 0.5)' }}>
            <button
              type="button"
              className="btn btn-primary rounded-3"
              onClick={reply}
            >
              Responder
            </button>

            <button
              type="button"
              className="btn btn-primary rounded-3"
              onClick={resend}
            >
              Reenviar
            </button>
          </div>
        </div>
      ) : (
        <CreateEmail onClose={onClose} mail={mail} send={send} resend={resend} reply={reply} mode={mode} />
      )}
      <ToastContainer />
    </div>
  );
};

EmailModal.propTypes = {
  mail: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmailModal;
