import * as Yup from 'yup';

export const quoteValidation = Yup.object().shape({

        nombre: Yup.string()
                .required('Este campo es obligatorio'),
        
        nif: Yup.string().test('nif', 'El NIF no es válido', value => {
                // if (!value) return true; // Permitir campo vacío, ajusta según tus necesidades
        
                const regexNif = /^[0-9]{8}[A-Za-z]$/;
                // Validación del CIF español
                const regexCif = /^[A-Za-z]{1}[0-9]{7}[A-Za-z0-9]{1}$/;
        
                return regexNif.test(value) || regexCif.test(value);
        }),
              
        domicilio: Yup.string()
                .required('Este campo es obligatorio'),

        poblacion: Yup.string()
                .required('Este campo es obligatorio'),

        provincia: Yup.string()
                .required('Este campo es obligatorio'),

        cp: Yup.string()
                .matches(/^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/, 'El código postal no es válido')
                .required('El código postal es obligatorio'),

        telefono1: Yup.string()
                .matches(/^[0-9]{9}$/, 'El número de teléfono no es válido')
                .required('Introduce al menos un número de teléfono'),

        telefono2: Yup.string()
                .matches(/^[0-9]{9}$/, 'El número de teléfono debe tener 9 dígitos'),

        email: Yup.string()
                .required('El campo email es obligatorio')
                .email('El formato de email no es correcto'),

        domicilioExplotacion: Yup.string()
                .required('Este campo es obligatorio'),

        construccion: Yup.number()
                .min('1920', 'La fecha de construcción debe ser a partir de 1920')
                .required('Este campo es obligatorio'),

        poblacionExplotacion: Yup.string()
                .required('Este campo es obligatorio'),

        provinciaExplotacion: Yup.string()
                .required('Este campo es obligatorio'),

        cpExplotacion: Yup.string()
                .matches(/^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/, 'El código postal no es válido')
                .required('El código postal es obligatorio'),

        superficieConstruida: Yup.number()
                .required('Este campo es obligatorio'),

        renovacionTuberias: Yup.date()
                .min(new Date(1950, 0, 1), 'La fecha debe ser a partir de 1950')
                .max(new Date(), 'La fecha no puede ser posterior al año actual')
                .typeError('Debe ser una fecha válida')
                .required('La fecha de renovación es obligatoria'),

        propiedad: Yup.string()
                .required('Debes escoger al menos una opción')
                .oneOf(['propiedad', 'alquilado'], 'Debes escoger al menos una opción')
                .label('Selecciona una opción'),

        facturacion: Yup.number()
                .required('Este campo es obligatorio'),

        empleados: Yup.number()
                .required('Este campo es obligatorio'),

        cerramientos: Yup.string()
                .required('Debes escoger al menos una opción')
                .oneOf(['incombustible', 'panelSandwich', 'poliuretano', 'combustible'], 'Debes escoger al menos una opción')
                .label('Selecciona una opción'),

        cubierta: Yup.string()
                .required('Debes escoger al menos una opción')
                .oneOf(['incombustible', 'panelSandwich', 'poliuretano', 'combustible'], 'Debes escoger al menos una opción')
                .label('Selecciona una opción'),

        quimicos: Yup.string()
                .required('Debes escoger al menos una opción')
                .oneOf(['ninguno', 'gasoil', 'gasolina', 'gases'], 'Debes escoger al menos una opción')
                .label('Selecciona una opción'),

        cantidadQuimicos: Yup.number()
                .required('Este campo es obligatorio'),

        proteccionIncendios: Yup.string()
                .required('Debes escoger al menos una opción')
                .oneOf(['extintores', 'bocasIncendios', 'hidratantes'], 'Debes escoger al menos una opción')
                .label('Selecciona una opción'),

        equino: Yup.boolean(),

        tipoEquino: Yup.string()
                //     .required('Debes escoger al menos una opción')
                .oneOf(['noCarnica', 'carnica'], 'Debes escoger al menos una opción')
                .label('Selecciona una opción'),

        ovinoCaprino: Yup.boolean(),

        tipoOvinoCaprino: Yup.string()
                //     .required('Debes escoger al menos una opción')
                .oneOf(['noCarnica', 'carnica'], 'Debes escoger al menos una aplicación')
                .label('Selecciona una opción'),

        porcino: Yup.boolean(),

        tipoPorcino: Yup.string()
                //     .required('Debes escoger al menos una opción')
                .oneOf(['cebo', 'engorde', 'ciclo'], 'Debes escoger al menos una opción')
                .label('Selecciona una opción'),

        avicola: Yup.boolean(),

        tipoAvicola: Yup.string()
                //     .required('Debes escoger al menos una opción')
                .oneOf(['pollos', 'pavos'], 'Debes escoger al menos una opción')
                .label('Selecciona una opción'),

        otros: Yup.boolean(),

        otrosTiposExplotacion: Yup.string(),

        continente: Yup.number(),

        mobiliario: Yup.number(),

        existencias: Yup.number(),

        cabezas: Yup.number(),

        otrasExistencias: Yup.number(),

        vehiculos: Yup.number(),

        naveCerrada: Yup.number(),

        naveAbierta: Yup.number(),

        superficieConstruidaConVivienda: Yup.number(),

        fechaConstruccion: Yup.number(),

        continenteConVivienda: Yup.number(),

        mobiliarioConVivienda: Yup.number(),

        enviarCopia: Yup.boolean(),

        observaciones: Yup.string(),

        // adjunto: Yup.string(),

})



export const quoteInitialValues = {

        nombre: '',
        nif: '',
        domicilio: '',
        poblacion: '',
        provincia: '',
        cp: '',
        telefono1: '',
        telefono2: '',
        email: '',
        domicilioExplotacion: '',
        construccion: '',
        poblacionExplotacion: '',
        provinciaExplotacion: '',
        cpExplotacion: '',
        superficieConstruida: '',
        renovacionTuberias: '',
        propiedad: '',
        facturacion: '',
        empleados: '',
        situacionExplotacion: 'Despoblado',
        cerramientos: '',
        cubierta: '',
        quimicos: '',
        cantidadQuimicos: 0,
        proteccionIncendios: '',
        // vacunoCarne: false,
        // vacunoLeche: false,
        // equino: false,
        tipoEquino: '',
        // ovinoCaprino: false,
        tipoOvinoCaprino: '',
        // porcino: false,
        tipoPorcino: '',
        // avicola: false,
        tipoAvicola: '',
        // otros: false,
        otrosTiposExplotacion: '',
        continente: '',
        mobiliario: '',
        existencias: '',
        cabezas: '',
        otrasExistencias: '',
        vehiculos: '',
        naveAbierta: '',
        naveCerrada: '',
        superficieConstruidaConVivienda: '',
        fechaConstruccion: '',
        continenteConVivienda: '',
        mobiliarioConVivienda: '',
        enviarCopia: '',
        observaciones: '',
        adjunto: '',
}
