import React from 'react';
import DocumentsForm from '../../components/pure/forms/DocumentsForm';
import Container from '../../Layouts/Container';


const UploadDocumentPage = () => {
    return (

        <>
            <h1>Gestión documental</h1>
            <p>Gestiona la subida de archivos de la plataforma</p>
            <DocumentsForm />
        </>

    );
}

export default UploadDocumentPage;
