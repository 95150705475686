import React from 'react';
import QuoteForm from '../../components/pure/forms/QuoteForm';

const AutoQuote = () => {
    return (
        <>
            <h1>Nueva solicitud de cotización</h1>

            <QuoteForm />
        </>
    );
}

export default AutoQuote;
