import React from 'react';
import PropTypes from 'prop-types';
import { format, isThisYear  } from 'date-fns'
import { es } from 'date-fns/locale'

const EmailItem = ({ mail, onOpen, changeStatus, deleteMail }) => {
    const dateObject = new Date(mail.emailData.headers.date);
    const formattedDate = format(dateObject, "dd MMMM, 'a las' HH:mm", { locale: es });
    const formattedYear = format(dateObject, "yyyy", { locale: es });
    const mostrarAnio = !isThisYear(dateObject);

    let from = mail.emailData.headers.from
    let subject = mail.emailData.headers.subject
    let date = mail.emailData.headers.date


    return ( 
        <div className='email-item-container d-flex'>
            <div 
                key={ mail.emailData.id } 
                className="email-item position-relative d-flex align-items-center gap-5 align-item-baseline bg-white border-bottom py-4 px-5"  
                onClick={() => {
                    onOpen(mail)
                }}
                style={ mail.emailData.labelIds.includes('UNREAD') ? {opacity: 1, borderLeft: '6px solid #0d6efd'} : {opacity: 0.7}}
            >

                <div className="d-flex flex-column me-3 flex-shrink-0">
                    <p className='mb-0 fw-bold'>{ from }</p>
                    <small className='mb-0'>{ mostrarAnio ? formattedDate . formattedYear : formattedDate }</small>
                </div>

                <div className="email-subject d-flex gap-1">
                    <p className='mb-0'>{subject} - { mail.emailData.snippet }</p>
                </div>

            </div>

            <button 
                className='dropdown-toggled-flex align-items-center justify-content-center btn btn-secondary rounded-0'
                type="button" 
                data-bs-toggle="dropdown" 
                aria-expanded="false"
            >

                <i className="bi bi-three-dots-vertical z-5"></i>

            </button>

            <ul className="dropdown-menu">
                <li 
                    className='dropdown-item cursor-pointer'  
                    onClick={ mail.emailData.labelIds.includes('UNREAD') ? () => changeStatus(mail, 1) : () => changeStatus(mail, 0) }
                >
                    {
                        mail.emailData.labelIds.includes('UNREAD')
                        ? <>
                            <i 
                                className="bi bi-envelope-open z-5"
                            ></i>
                            <span className='ms-2'>Marcar como leído</span>
                        </>
                        : <>
                            <i 
                                className="bi bi-envelope z-5"  
                            />
                            <span className='ms-2'>Marcar como no leido</span>
                        </>
                    }  
                </li>
                <li className='dropdown-item cursor-pointer'>
                    <i className="bi bi-trash z-5"></i>
                    <span className='ms-2' onClick={() => deleteMail(mail)}>Eliminar</span>
                </li>
            </ul>
        </div>
    );
};


EmailItem.propTypes = {
    mail: PropTypes.object.isRequired, 
    onOpen: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired
};


export default EmailItem;
