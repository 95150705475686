import React, { useState, useEffect, useCallback } from "react";
import { Navigate, Routes, Route, BrowserRouter, Redirect, Switch } from "react-router-dom";
import { useAuth } from "../utils/AuthProvider";
import AuthVerify from "../commom/AuthVerify";
import ErrorPage from "../Pages/ErrorPage";
import Dashboard from "../Pages/dashboard/Dashboard";
import QuotesPage from "../Pages/quotes/QuotesPage";
import DocumentsPage from "../Pages/documents/DocumentsPage";
import UsersPage from "../Pages/users/UsersPage";
import EmailPage from "../Pages/email/EmailPage";
import DocumentsList from "../Pages/documents/DocumentsList";
import UploadDocumentPage from "../Pages/documents/UploadDocumentPage";
import AutoQuote from "../Pages/quotes/AutoQuote";
import HogarQuote from "../Pages/quotes/HogarQuote";
import VidaQuote from "../Pages/quotes/VidaQuote";
import AgrarioQuote from "../Pages/quotes/AgrarioQuote";
import SaludQuote from "../Pages/quotes/SaludQuote";
import ThirdPartyLinks from "../Pages/quotes/ThirdPartyLinks";
import QuotesList from "../Pages/quotes/QuotesList";
import LoginPage from "../Pages/login/LoginPage";
import { GoogleOAuthProvider } from "@react-oauth/google";

const PrivateRoute = ({ element: Element, roles }) => {
  const { authState } = useAuth();

  // Verificar si el usuario tiene permiso para acceder a la ruta
  const isAllowed = () => {
    return authState.roles && roles.some((role) => authState.roles.includes(role));
  };

  return isAllowed() ? Element : <Navigate to="/login" />;
};

const AppRouter = () => {
  const { authState, dispatch } = useAuth();

  const [isLogged, setIsLogged] = useState(false);

  const [gmailLogged, setGmailLogged] = useState(false);

  const logOut = useCallback(() => {
   
  }, [dispatch]);

  useEffect(() => {
    authState.user === null ? setIsLogged(false) : setIsLogged(true);

    authState.googleCredential === null
      ? setGmailLogged(false)
      : setGmailLogged(true);
  }, [authState]);

  // const clientId = process.env.REACT_APP_CLIENT_ID;

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
          <Routes>
            <Route index path="/" element={<Dashboard />} />

            <Route 
              path="/buzon" 
              element={
                <PrivateRoute element={<EmailPage />} roles={["Admin", "Sucursal"]} />
              }   
            />

            <Route path="/solicitud-de-cotizaciones" element={<QuotesPage />}>
              <Route path="autos" element={<AutoQuote />} />
              <Route path="agrario" element={<AgrarioQuote />} />
              <Route path="hogar" element={<HogarQuote />} />
              <Route path="vida" element={<VidaQuote />} />
              <Route path="salud" element={<SaludQuote />} />
              <Route path="enlaces" element={<ThirdPartyLinks />} />
              <Route path="listado" element={<QuotesList />} />
            </Route>

            <Route path="gestion-documental" element={<DocumentsPage />}>
              <Route index path="documentos" element={<DocumentsList />} />
              <Route path="subir-archivo" element={<UploadDocumentPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>

            <Route path="/usuarios" element={<UsersPage />} />
            <Route
              path="/login"
              element={isLogged ? <Navigate to="/" /> : <LoginPage />}
            />

            <Route path="*" element={<ErrorPage />} />
          </Routes>
          
          <AuthVerify logOut={ () => dispatch({ type: "LOGOUT" }) } />

      </GoogleOAuthProvider>
    </>
  );
};

export default AppRouter;
